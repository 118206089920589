import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultSubscriptionReferralCode } from "@kinherit/sdk/index";

export const RecordKinvaultSubscriptionReferralCode = DefineReadAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/kinvaults/subscription-referral-codes/{kinvaultSubscriptionReferralCode}",
  method: "get",
  parse: (message: string | KinvaultSubscriptionReferralCode) => ({
    params: {
      kinvaultSubscriptionReferralCode: message,
    },
  }),
  rootModel: KinvaultSubscriptionReferralCode,
});
