// BrandedKinvault

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { BrandedKinvault, BrandedKinvaultCta } from "@kinherit/sdk/index";

export const DeleteBrandedKinvaultCta = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault-cta/{brandedKinvaultCta}",
  method: "delete",
  parse: (brandedKinvaultCta: BrandedKinvaultCta) => ({
    params: {
      brandedKinvaultCta,
    },
  }),
  after: ({ message }) => {
    BrandedKinvault.$delete(message);
  },
});
