<template>
  <Card title="Company Leaderboard (top 100)">
    <p>For a given period, this displays:</p>
    <ul>
      <li>
        the number of leads received and the subsequent conversion of those
        leads
      </li>
      <li>the number of accounts created and the subsequent dropout rate</li>
      <li>the total cash in and the average order value</li>
    </ul>
    <p>
      <strong
        >There is no relationship between leads > account created > cash. They
        are seperate metrics.</strong
      >
    </p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
    />
    <DashboardStatisticWidgetGroup v-bind="totalStats" class="mt-3" />

    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="columns"
      :rows="results"
      @loaded="load"
    >
    </Table>
  </Card>
</template>

<script lang="ts">
import Table from "@kinherit/framework/component.display/table";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import { defineComponent } from "vue";
import { CompanyLeadboardReportRoute } from ".";
import { ReadCompaniesLeaderboardReportFilters } from "../../form/read-companies-leaderboard-report.form";
import { Currency } from "@kinherit/ts-common/index";
import DashboardStatisticWidgetGroup from "@/shared/component/widget/DashboardStatisticWidgetGroup.vue";
import { TDashboardStatisticWidgetGroupProps } from "@/shared/component/widget/dashboard-statistic-widget";

export default defineComponent({
  name: CompanyLeadboardReportRoute,
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.reports)],
  components: {
    Card,
    MasterListFilters,
    Table,
    DashboardStatisticWidgetGroup,
  },
  data: () => ({
    results: [] as any[],
    filters: ReadCompaniesLeaderboardReportFilters(),
    columns: [
      {
        title: "Company",
        mapHtml: (row: any) => {
          return `<strong>${row.referralCodeName}</strong><small class='is-block'>${row.referralCode} (${row.referralCategory})</small>`;
        },
      },
      {
        title: "Leads",
        field: "totalReferrals",
      },
      {
        title: "Conv",
        mapHtml: (row: any) => {
          const convertedPercentage =
            Number(row.totalReferrals) === 0
              ? 0
              : Number(row.convertedLeadCount) / Number(row.totalReferrals);
          const cssClass =
            convertedPercentage > 0.7
              ? "has-text-success"
              : convertedPercentage > 0.5
              ? "has-text-warning-dark"
              : "has-text-danger";
          const percentage =
            typeof convertedPercentage === "number"
              ? (convertedPercentage * 100).toFixed(0)
              : "-";
          return `<strong class='${cssClass}'>${percentage}%</strong>`;
        },
      },
      {
        title: "Accounts",
        field: "totalAccounts",
        class: "has-border-left",
      },
      {
        title: "Dropout",
        mapHtml: (row: any) => {
          const cssClass =
            row.accountDropoutRate > 25
              ? "has-text-danger"
              : row.accountDropoutRate > 17
              ? "has-text-warning-dark"
              : "has-text-success";
          const percentage =
            typeof row.accountDropoutRate === "number"
              ? row.accountDropoutRate.toFixed(0)
              : "-";
          return `<strong class='${cssClass}'>${percentage}%</strong>`;
        },
      },
      {
        title: "Cash In",
        class: "has-border-left",
        mapHtml: (row: any) => {
          const totalOrderValue = new Currency({
            amount: row.totalOrderValue,
          });

          return `${totalOrderValue.toFormattedString()}`;
        },
      },
      {
        title: "No.",
        mapHtml: (row: any) => {
          return row.totalOrderCount;
        },
      },
      {
        title: "AVG",
        mapHtml: (row: any) => {
          const averageOrderValue =
            row.totalOrderValue > 0
              ? new Currency({
                  amount: row.totalOrderValue / row.totalOrderCount,
                }).format
              : "-";
          return `<span class='has-text-grey'>${averageOrderValue}</span>`;
        },
      },
    ],
    totalStats: {} as TDashboardStatisticWidgetGroupProps,
  }),
  methods: {
    async load() {
      const { datasets, totals } = await this.$actionBus.CompaniesLeaderboard(
        this.filters.localData,
      );

      this.results = datasets;
      this.totalStats = {
        title: "Totals & Averages for selected period and filters",
        details: [
          {
            label: "Leads",
            value: totals.totalReferrals.toString(),
            subValue: "Conv: " + totals.averageConversionRate.toFixed(0) + "%",
          },
          {
            label: "Accounts",
            value: totals.totalAccounts,
            subValue: "Dropout: " + totals.averageDropoutRate.toFixed(0) + "%",
          },
          {
            label: "Cash In",
            value: new Currency({
              amount: totals.totalOrderValue,
            }).format,
            subValue: totals.totalOrderCount + " orders",
          },
          {
            label: "Avg Sale",
            subValue: "-",
            value: new Currency({
              amount: totals.averageOrderValue,
            }).format,
          },
        ],
      };
    },
  },
});
</script>
