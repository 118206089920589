// EmailTemplate

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { BrandedKinvaultCta } from "@kinherit/sdk/index";

export const ReadBrandedKinvaultCta = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault-cta",
  method: "get",
  rootModel: BrandedKinvaultCta,
  parse: () => ({}),
});
