import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultSubscriptionReferralCode } from "@kinherit/sdk/index";

export const ReadKinvaultSubscriptionReferralCode = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/subscription-referral-codes",
  method: "get",
  parse: () => ({}),
  rootModel: KinvaultSubscriptionReferralCode,
});
