import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultCoupon, KinvaultPrice } from "@kinherit/sdk/index";

export const RecordKinvaultCoupon = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/coupons/{kinvaultCoupon}",
  method: "get",
  parse: (message: string | KinvaultCoupon) => ({
    params: {
      kinvaultCoupon: message,
    },
  }),
  rootModel: KinvaultPrice,
});
