import {
  RouteInterface,
  RouteParamObject,
} from "@kinherit/framework/core/route-config";

export const ReportRoute = "Report";
const ReportURI = "/reports";
export type ReportParams = RouteParamObject<typeof ReportURI>;

export const CoreReportsRoute = "CoreReportsRoute";
const CoreReportsUri = "/reports/core";

export const SalesReportsRoute = "SalesReportsRoute";
const SalesReportsUri = "/reports/sales";

export const KinvaultReportsRoute = "KinvaultReportsRoute";
const KinvaultReportsUri = "/reports/kinvault";

import { Routes as CoreRoutes } from "./core";
import { Routes as KinvaultRoutes } from "./kinvault";
import { Routes as SalesRoutes } from "./sales";

export const Routes: Array<RouteInterface> = [
  {
    path: ReportURI,
    name: ReportRoute,
    component: () =>
      import(/* webpackChunkName: "report-details" */ "./Reports.vue"),
    // meta: {
    //   breadcrumbs: (params) => [
    //     {
    //       text: "Reports",
    //       route: { name: ReportRoute, params },
    //     },
    //   ],
    // },
    children: [
      {
        name: CoreReportsRoute,
        path: CoreReportsUri,
        redirect: { name: "SummaryReportRoute" },
        children: CoreRoutes,
      },
      {
        name: SalesReportsRoute,
        path: SalesReportsUri,
        redirect: { name: "CompanyLeadboardReportRoute" },
        children: SalesRoutes,
      },
      {
        name: KinvaultReportsRoute,
        path: KinvaultReportsUri,
        redirect: { name: "SignupsReportRoute" },
        children: KinvaultRoutes,
      },
    ],
  },
];
