// Subscription

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Subscription } from "@kinherit/sdk/model/subscription";

export const UpdateSubscription = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/physical-storage-subscriptions/{physicalStorageSubscription}",
  method: "patch",
  parse: (target: Subscription) => ({
    body: {
      billingDate: target.$data.billingDate,
      procXero: target.$data.procXero,
      procDd: target.$data.procDd,
      notes: target.$data.notes,
      subscriptionValue: target.$data.subscriptionValue,
      billingEndDate: target.$data.billingEndDate,
      xeroRepeatingInvoiceID: target.$data.xeroRepeatingInvoiceID,
      fileCount: target.$data.fileCount,
      kintin: target.$data.kintin,
      status: target.$data.status,
      productSku: target.$data.productSku,
      provider: target.$data.provider,
    },
    params: {
      physicalStorageSubscription: target,
    },
  }),
  after: ({ message }) => {
    message.$persist();
  },
});
