<template>
  <div class="introducer-company-master-list">
    <PageHeader htag="h1" :text="title">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          class="create"
          size="is-normal"
          @click="handleCreate"
        >
          Add Company
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :is-clickable="false"
      :row-classes="rowClasses"
      :sort-by-options="{
        createdAt: 'Created',
        'profile.organisationName': 'Name',
      }"
      v-model:sort-by="sort.by"
      tracking-field="id"
      v-model:sort-direction="sort.direction"
      v-model:value="selected"
    >
      <template #tableControls>
        <Button
          v-if="$auth.hasRole('admin')"
          class="reassign"
          @click="reassign"
          icon-left="PersonTie"
          aria-label="Reassign Companies"
          tooltip="Reassign Companies"
          color="is-plain"
          size="is-small"
        />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyMasterListWrapper",
  "route": "IntroducerCompanyMasterList",
  "selector": ".introducer-company-master-list",
  "imports": {
    "ReadIntroducerCompanyFormWrapper": "@/module/introducer.company/form/read-introducer-company.form.test",
    "IntroducerCompanyDetailsWrapper": "@/module/introducer.company/page/details/IntroducerCompanyDetails.test",
    "CreateIntroducerCompanyFormWrapper": "@/module/introducer.company/form/create-introducer-company.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadIntroducerCompanyFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "IntroducerCompanyDetailsWrapper"
    },
    "createIntroducerCompany": {
      "type": "click",
      "selector": ".create"
    },
    "createIntroducerCompanyForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "CreateIntroducerCompanyFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { IntroducerCompanyTableColumns } from "@/config/table-columns.config";
import { ReadIntroducerCompanyForm } from "@/module/introducer.company/form/read-introducer-company.form";
import { ReassignIntroducerCompaniesForm } from "@/module/introducer.company/form/reassign-introducer-companies.form";
import { AuthService } from "@/service/auth.service";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, In, Like } from "@kinherit/orm/index";
import { IIntroducerCompany, IntroducerCompany, Tag } from "@kinherit/sdk";
import { defineComponent, type PropType } from "vue";
import { IntroducerCompanyMasterListRoute } from ".";
import { CreateIntroducerCompanyForm } from "../form/create-introducer-company.form";

export default defineComponent({
  name: IntroducerCompanyMasterListRoute,
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.introducer),
  ],
  components: { MasterListPage, Button, PageHeader },
  props: {
    tag: {
      type: Object as PropType<Tag>,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: [] as IntroducerCompany[],
    filters: ReadIntroducerCompanyForm(),
    columns: IntroducerCompanyTableColumns,
    rows: Array<IntroducerCompany>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IIntroducerCompany,
      direction: "desc" as "asc" | "desc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("introducer-company:write");
    },
    title(): string | null {
      if (this.hideTitle) {
        return null;
      }

      return "Introducer Companies";
    },
  },
  async mounted(): Promise<void> {
    if (this.tag) {
      this.filters.localData.tags = [this.tag.id];
    }
  },
  methods: {
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "has-background-danger-light": scope.row.status?.value === "dead",
        "has-background-warning-light": scope.row.status?.value === "dormant",
      };
    },
    async refresh(): Promise<void> {
      // const data = await window.Kernel.ActionBus.introducerCompany.company.read(
      const data = await this.$actionBus.company.ReadIntroducerCompanies({
        query: this.getFilters(),
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.introducerCompany;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    getFilters() {
      const formData = this.filters.localData;

      return [
        {
          profile: {
            fullName: Like(formData.search),
          },
          tags: {
            id: In(formData.tags),
          },
          status: {
            id: In(formData.status),
          },
          type: {
            id: In(formData.type),
          },
          stage: {
            id: In(formData.stage),
          },
          assignedTo: {
            id: In(formData.assignedUser),
          },
          seniorAdviser: {
            id: In(formData.seniorAdviser),
          },
          network: In(formData.network),
          introducedBy: {
            id: In(formData.introducedBy),
          },
          createdAt: Between(formData.created),
        },
        {
          profile: {
            organisationName: Like(formData.search),
          },
          tags: {
            id: In(formData.tags),
          },
          status: {
            id: In(formData.status),
          },
          type: {
            id: In(formData.type),
          },
          stage: {
            id: In(formData.stage),
          },
          assignedTo: {
            id: In(formData.assignedUser),
          },
          seniorAdviser: {
            id: In(formData.seniorAdviser),
          },
          network: In(formData.network),
          introducedBy: {
            id: In(formData.introducedBy),
          },
          createdAt: Between(formData.created),
        },
      ];
    },
    async handleCreate(): Promise<void> {
      const form = CreateIntroducerCompanyForm();

      try {
        const { company, contact } = await form.dialog({
          dialog: {
            title: "Add Introducer Company",
          },
          button: {
            ok: {
              text: "Add",
            },
          },
        });

        // await window.Kernel.ActionBus.introducerCompany.company.create({
        const { introducerCompany } =
          await this.$actionBus.company.CreateIntroducerCompany(company);

        contact.companies = introducerCompany;

        // await window.Kernel.ActionBus.introducerContact.contact.create({
        //   introducerContact: contact,
        // });
        await this.$actionBus.contact.CreateIntroducerContact(contact);

        this.refresh();
      } catch {
        form.localData.company.$delete({
          profile: {
            addresses: true,
            emails: true,
            phoneNumbers: true,
            websites: true,
          },
        });
        form.localData.contact.$delete({
          profile: {
            addresses: true,
            emails: true,
            phoneNumbers: true,
            websites: true,
          },
        });
        return;
      }
    },
    async reassign(): Promise<void> {
      const { assignedTo, defaultLegalAssistant, seniorAdviser } =
        await ReassignIntroducerCompaniesForm(
          this.$data.selected,
          this.pagination.count,
        ).dialog({
          dialog: {
            title: "Reassign Introducer Companies",
          },
        });

      await this.$actionBus.company.ReassignIntroducerCompanies({
        message: {
          assignedTo,
          defaultLegalAssistant,
          seniorAdviser,
        },
        query: this.selected.isNotEmpty()
          ? {
              id: In(this.selected.pluck("id")),
            }
          : this.getFilters(),
      });

      this.refresh();
    },
  },
});
</script>
