import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KinvaultSubscription } from "@kinherit/sdk/index";

export const CreateKinvaultSubscription = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/subscriptions",
  method: "post",
  parse: (message: KinvaultSubscription) => ({
    params: {
      kinvaultSubscription: message,
    },
    body: {
      stripeSubscriptionId: message.$data.stripeSubscriptionId,
      status: message.$data.status,
      referralCode: message.$data.referralCode,
      kintin: message.$data.kintin,
      stripeSessionId: message.$data.stripeSessionId,
    },
  }),
  after: ({ message }) => {
    KinvaultSubscription.$delete(message);
  },
});
