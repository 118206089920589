// Subscription, PhysicalStorageFile, Product, PhysicalStorageFileLog

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { Subscription } from "@kinherit/sdk/model/subscription";

export const DeleteSubscription = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/kinvault/{kintin}/physical-storage-subscription/{physicalStorageSubscription}",
  method: "delete",
  parse: (message: {
    kintin: Kintin | string;
    physicalStorageSubscription: Subscription | string;
  }) => ({
    params: {
      kintin: message.kintin,
      physicalStorageSubscription: message.physicalStorageSubscription,
    },
  }),
  after: ({ message }) => {
    Subscription.$delete(message.physicalStorageSubscription);
  },
});
