import {
  CreatedAtField,
  KinvaultSubscriptionReferralCodeField,
  SharedFilterProps,
} from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";

export const ReadKinvaultSubscriptionForm = (): FormBuilder<
  () => {
    friendlyName: string;
    status:
      | null
      | "incomplete"
      | "incomplete_expired"
      | "trialing"
      | "active"
      | "past_due"
      | "canceled"
      | "unpaid"
      | "paused";
    stripeSubscriptionId: string;
    referralCode: string[];
    created: null | [number, number];
  },
  string
> => {
  return MasterListFiltersForm({
    name: "filter-kinvault-subscription-form",
    data: () => ({
      friendlyName: "",
      status: null,
      stripeSubscriptionId: "",
      referralCode: [],
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      friendlyName: [
        FormTextField({
          props: {
            label: "Account Name",
          },
          models: {
            value: "friendlyName",
          },
        }),
      ],
      stripeSubscriptionId: [
        FormTextField({
          props: {
            label: "Stripe Subscription ID",
          },
          models: {
            value: "stripeSubscriptionId",
          },
        }),
      ],
      status: [
        FormSelectField({
          props: {
            options: {
              null: "Any",
              incomplete: "Incomplete",
              incomplete_expired: "Incomplete Expired",
              trialing: "Trialing",
              active: "Active",
              past_due: "Past Due",
              canceled: "Canceled",
              unpaid: "Unpaid",
              paused: "Paused",
            },
            label: "Status",
          },
          models: {
            value: {
              get: (data) => {
                return data.status || "null";
              },
              set: (value, data) => {
                data.status = value === "null" ? null : value;
              },
            },
          },
        }),
      ],
      referralCode: [
        KinvaultSubscriptionReferralCodeField({
          props: {
            label: "Referral Code",
            vModel: "referralCode",
            isMultiSelect: true,
          },
          simplifyData: true,
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
