<template>
  <Card v-if="$data.form">
    <Form :config="$data.form" is-borderless />
  </Card>
  <Card v-if="$data.form" title="Conditions">
    <template #buttons>
      <Button
        icon-left="Plus"
        aria-label="Add a condition"
        tooltip="Add a condition"
        @click="addCondition"
        color="is-positive"
      />
    </template>
    <div
      v-for="(condition, index) in $data.form.localData.conditions"
      :key="`condition-${index}`"
    >
      <div class="columns">
        <div class="column">
          <SelectField
            :value="condition.type"
            @update:value="(val: any) => setConditionType(index, val)"
            :options="conditionTypes"
            label="Type"
            :validators="['required']"
          />
        </div>
        <div class="column">
          <NumberField
            :value="condition.count"
            @update:value="(val: any) => setConditionCount(index, val)"
            label="Count"
            :validators="['required']"
          />
        </div>
        <div class="column">
          <SelectField
            :value="condition.target"
            @update:value="(val: any) => setConditionTarget(index, val)"
            :options="conditionTargets"
            label="Target"
            :validators="['required']"
          />
        </div>
        <div class="column is-narrow" style="align-content: end">
          <Button
            @click="deleteCondition(index)"
            color="is-danger"
            icon-left="Trash"
            aria-label="Delete this condition"
            tooltip="Delete this condition"
          />
        </div>
      </div>
    </div>
  </Card>
  <Card v-if="$data.form" title="Buttons">
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit buttons styles"
        tooltip="Edit buttons styles"
        @click="editButtonsStyles"
        color="is-info"
        :text="`${
          Object.keys($data.form.localData.content.buttons.styles).length
        }`"
      />
      <Button
        icon-left="Plus"
        aria-label="Add a button"
        tooltip="Add a button"
        @click="addButton"
        color="is-positive"
      />
    </template>
    <div class="button-options">
      <SelectField
        :value="$data.form.localData.content.buttons.side"
        @update:value="(val: any) => setButtonSide(val)"
        :options="buttonSides"
        label="Side"
        :validators="['required']"
      />
    </div>
    <div
      v-for="(button, index) in $data.form.localData.content.buttons.items"
      :key="`button-${index}`"
    >
      <div class="columns pt-4">
        <div class="column">
          <TextField
            :value="button.text"
            @update:value="(val: any) => setButtonText(index, val)"
            label="Text"
            :validators="['required']"
          />
        </div>
        <div class="column">
          <UrlField
            :value="button.link"
            @update:value="(val: any) => setButtonLink(index, val)"
            label="URL"
            :validators="['required']"
          />
        </div>
        <div class="column is-narrow">
          <SelectField
            label="Color"
            :options="buttonColors"
            :value="button.color"
            @update:value="(val: any) => setButtonColor(index, val)"
            :validators="['required']"
          />
        </div>
        <div class="column is-narrow" style="align-content: end">
          <Button
            @click="editButtonStyles(index)"
            color="is-info"
            icon-left="Edit"
            aria-label="Edit button styles"
            tooltip="Edit button styles"
            :text="`${Object.keys(button.styles).length}`"
          />
        </div>
        <div class="column is-narrow" style="align-content: end">
          <Button
            @click="deleteButton(index)"
            color="is-danger"
            icon-left="Trash"
            tooltip="Delete this button"
            aria-label="Delete this button"
          />
        </div>
      </div>
    </div>
  </Card>
  <Card v-if="$data.form" title="Text">
    <template #buttons>
      <Button
        icon-left="Edit"
        aria-label="Edit title styles"
        tooltip="Edit title styles"
        @click="editTitleStyles"
        color="is-info"
        :text="`${
          Object.keys($data.form.localData.content.title.styles).length
        }`"
      />
      <Button
        icon-left="Plus"
        aria-label="Add a text"
        tooltip="Add a text"
        @click="addText"
        color="is-positive"
      />
    </template>
    <div
      v-for="(text, index) in $data.form.localData.content.texts"
      :key="`text-${index}`"
    >
      <div class="columns pt-4">
        <div class="column">
          <RichTextField
            :value="text.text"
            @update:value="(val: any) => setText(index, val)"
            label="Text"
            :validators="['required']"
          />
        </div>
        <div class="column">
          <NumberField
            :value="text.fontSize"
            @update:value="(val: any) => setTextFontSize(index, val)"
            label="Font Size"
            :min="1"
            :max="6"
            :validators="['required']"
          />
          <div class="buttons is-right">
            <Button
              @click="editTextStyles(index)"
              color="is-info"
              icon-left="Edit"
              aria-label="Edit text styles"
              tooltip="Edit text styles"
              :text="`${Object.keys(text.styles).length}`"
            />
            <Button
              @click="deleteText(index)"
              color="is-danger"
              icon-left="Trash"
              aria-label="Delete this text"
              tooltip="Delete this text"
            />
          </div>
        </div>
      </div>
    </div>
  </Card>
  <Card v-if="form" title="Preview">
    <BrandedKinvaultCtaDefault
      style="border: 4px solid black; padding: 1rem"
      :content="form.localData.content"
      :title="form.localData.title"
    />
    <div class="buttons is-right">
      <Button @click="save" color="is-success" text="Save" />
      <Button @click="cancel" color="is-danger" text="Cancel" />
    </div>
  </Card>
</template>

<script lang="ts">
import { UpdateBrandedKinvaultCtaForm } from "@/module/admin.branded-kinvault-cta/form/update-branded-kinvault-cta.form";
import { UpdateStylesForm } from "@/module/admin.branded-kinvault-cta/form/update-styles.form";
import { BrandedKinvaultCtaDefault } from "@kinherit/framework/component.display/branded-kinvault-cta";
import Form from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import NumberField from "@kinherit/framework/component.input/number-field";
import RichTextField from "@kinherit/framework/component.input/rich-text-field";
import SelectField from "@kinherit/framework/component.input/select-field";
import TextField from "@kinherit/framework/component.input/text-field";
import UrlField from "@kinherit/framework/component.input/url-field";
import Card from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { BrandedKinvaultCta } from "@kinherit/sdk/index";
import {
  BrandedKinvaultCtaConditions,
  BrandedKinvaultCtaDefaultContent,
  DateTime,
  Uuid,
} from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import {
  AdminBrandedKinvaultCtaCreateRoute,
  AdminBrandedKinvaultCtaUpdateRouteParams,
} from ".";

export default defineComponent({
  name: AdminBrandedKinvaultCtaCreateRoute,
  mixins: [
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal2.admin.brandedKinvaults.cta,
    ),
  ],
  components: {
    Form,
    Card,
    SelectField,
    TextField,
    UrlField,
    Button,
    NumberField,
    RichTextField,
    BrandedKinvaultCtaDefault,
  },
  data: () => ({
    form: null as null | FormBuilder<() => BrandedKinvaultCta, string>,
    buttonColors: {
      "is-success": "Success",
      "is-danger": "Danger",
      "is-primary": "Primary",
      "is-info": "Info",
      "is-warning": "Warning",
      "is-positive": "Positive",
    } satisfies Record<
      BrandedKinvaultCtaDefaultContent["buttons"]["items"][number]["color"],
      string
    >,
    conditionTypes: {
      equals: "Equals",
      moreThan: "More than",
      lessThan: "Less than",
    } satisfies Record<BrandedKinvaultCtaConditions[number]["type"], string>,
    conditionTargets: {
      contractors: "Contractors",
      insurance: "Insurance",
      other: "Other",
      pets: "Pets",
      utilities: "Utilities",
      vehicles: "Vehicles",
      work: "Work",
      accounts: "Accounts",
      "business-assets": "Business Assets",
      debts: "Debts",
      investments: "Investments",
      pensions: "Pensions",
      policies: "Policies",
      properties: "Properties",
      valuables: "Valuables",
      "account-holders": "Account Holders",
      charities: "Charities",
      companies: "Companies",
      "other-people": "Other People",
      "trusted-people": "Trusted People",
      "funerial-wishes": "Funerial Wishes",
      "other-requests": "Other Requests",
      "personal-notes": "Personal Notes",
    } satisfies Record<BrandedKinvaultCtaConditions[number]["target"], string>,
    buttonSides: {
      "is-left": "Left",
      "is-right": "Right",
    } satisfies Record<
      BrandedKinvaultCtaDefaultContent["buttons"]["side"],
      string
    >,
  }),
  async mounted(): Promise<void> {
    let brandedKinvaultCta: BrandedKinvaultCta | null = null;
    if (this.$params.brandedKinvaultCta) {
      await this.$actionBus.RecordBrandedKinvaultCta({
        message: this.$params.brandedKinvaultCta,
      });

      brandedKinvaultCta = BrandedKinvaultCta.$findOne(
        this.$params.brandedKinvaultCta,
      );
    }

    this.$data.form = UpdateBrandedKinvaultCtaForm(
      brandedKinvaultCta ||
        new BrandedKinvaultCta({
          position: "top",
          template: "default",
          categories: ["admin-contractors"],
          internalName: "New CTA",
          referralCodes: [],
          title: "New CTA",
          displayFrom: new DateTime().sub(1, "day").formatMachine,
          displayTo: new DateTime().add(1, "day").formatMachine,
          content: {
            title: {
              styles: {},
            },
            buttons: {
              styles: {},
              items: [],
              side: "is-right",
            },
            texts: [],
          },
          conditions: [],
          id: Uuid.generate(),
          createdAt: new DateTime().formatMachine,
        }),
    );
  },
  computed: {
    $params(): Partial<AdminBrandedKinvaultCtaUpdateRouteParams> {
      return this.$route
        .params as Partial<AdminBrandedKinvaultCtaUpdateRouteParams>;
    },
    isCreate(): boolean {
      return !this.$params.brandedKinvaultCta;
    },
  },
  methods: {
    addButton(): void {
      const content = this.$data.form!.localData.content;
      content.buttons.items.push({
        styles: {},
        text: "",
        link: "",
        color: "is-primary",
      });
      this.$data.form!.localData.content = content;
    },
    deleteButton(index: number): void {
      const content = this.$data.form!.localData.content;
      content.buttons.items.splice(index, 1);
      this.$data.form!.localData.content = content;
    },
    addCondition(): void {
      const conditions = this.$data.form!.localData.conditions;
      conditions.push({
        type: "equals",
        count: 1,
        target: "contractors",
      });
      this.$data.form!.localData.conditions = conditions;
    },
    deleteCondition(index: number): void {
      const conditions = this.$data.form!.localData.conditions;
      conditions.splice(index, 1);
      this.$data.form!.localData.conditions = conditions;
    },
    addText(): void {
      const content = this.$data.form!.localData.content;
      content.texts.push({
        styles: {},
        text: "",
        fontSize: 6,
      });
      this.$data.form!.localData.content = content;
    },
    deleteText(index: number): void {
      const content = this.$data.form!.localData.content;
      content.texts.splice(index, 1);
      this.$data.form!.localData.content = content;
    },
    setButtonText(index: number, value: string): void {
      const content = this.$data.form!.localData.content;
      content.buttons.items[index].text = value;
      this.$data.form!.localData.content = content;
    },
    setButtonLink(index: number, value: string): void {
      const content = this.$data.form!.localData.content;
      content.buttons.items[index].link = value;
      this.$data.form!.localData.content = content;
    },
    setButtonColor(index: number, value: any): void {
      const content = this.$data.form!.localData.content;
      content.buttons.items[index].color = value;
      this.$data.form!.localData.content = content;
    },
    setButtonSide(value: any): void {
      const content = this.$data.form!.localData.content;
      content.buttons.side = value;
      this.$data.form!.localData.content = content;
    },
    setText(index: number, value: string): void {
      const content = this.$data.form!.localData.content;
      content.texts[index].text = value;
      this.$data.form!.localData.content = content;
    },
    setTextFontSize(index: number, value: any): void {
      const content = this.$data.form!.localData.content;
      content.texts[index].fontSize = value;
      this.$data.form!.localData.content = content;
    },
    setConditionType(index: number, value: any): void {
      const conditions = this.$data.form!.localData.conditions;
      conditions[index].type = value;
      this.$data.form!.localData.conditions = conditions;
    },
    setConditionCount(index: number, value: number): void {
      const conditions = this.$data.form!.localData.conditions;
      conditions[index].count = value;
      this.$data.form!.localData.conditions = conditions;
    },
    setConditionTarget(index: number, value: any): void {
      const conditions = this.$data.form!.localData.conditions;
      conditions[index].target = value;
      this.$data.form!.localData.conditions = conditions;
    },
    async save(): Promise<void> {
      if (!this.$data.form) {
        return;
      }

      const buttons = this.$data.form.localData.content.buttons.items;

      for (const button of buttons) {
        if (!button.text || !button.link) {
          return;
        }
      }

      const condition = this.$data.form.localData.conditions;

      for (const cond of condition) {
        if (!cond.count || !cond.target || !cond.type) {
          return;
        }
      }

      const texts = this.$data.form.localData.content.texts;

      for (const text of texts) {
        if (!text.text || !text.fontSize) {
          return;
        }
      }

      if (!this.$data.form.localData.content.buttons.side) {
        return;
      }

      if (this.isCreate) {
        await this.$actionBus.CreateBrandedKinvaultCta(
          this.$data.form.localData,
        );
      } else {
        await this.$actionBus.UpdateBrandedKinvaultCta(
          this.$data.form.localData,
        );
      }

      this.$router.back();
    },
    cancel(): void {
      if (!this.isCreate) {
        this.form?.localData.$delete();
      }

      this.$router.back();
    },
    async editButtonsStyles(): Promise<void> {
      if (!this.$data.form) {
        return;
      }

      const content = this.$data.form.localData.content;
      const styles = content.buttons.styles;

      await UpdateStylesForm(styles).dialog({
        dialog: {
          title: "Edit Styles",
        },
      });

      content.buttons.styles = styles;
      this.$data.form.localData.content = content;
    },
    async editTitleStyles(): Promise<void> {
      if (!this.$data.form) {
        return;
      }

      const content = this.$data.form.localData.content;
      const styles = content.title.styles;

      await UpdateStylesForm(styles).dialog({
        dialog: {
          title: "Edit Styles",
        },
      });

      content.title.styles = styles;
      this.$data.form.localData.content = content;
    },
    async editButtonStyles(index: number): Promise<void> {
      if (!this.$data.form) {
        return;
      }

      const content = this.$data.form.localData.content;
      const styles = content.buttons.items[index].styles;

      await UpdateStylesForm(styles).dialog({
        dialog: {
          title: "Edit Styles",
        },
      });

      content.buttons.items[index].styles = styles;
      this.$data.form.localData.content = content;
    },
    async editTextStyles(index: number): Promise<void> {
      if (!this.$data.form) {
        return;
      }

      const content = this.$data.form.localData.content;
      const styles = content.texts[index].styles;

      await UpdateStylesForm(styles).dialog({
        dialog: {
          title: "Edit Styles",
        },
      });

      content.texts[index].styles = styles;
      this.$data.form.localData.content = content;
    },
  },
});
</script>
