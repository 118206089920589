<template>
  <div class="kinvault-subscription-master-list">
    <PageHeader htag="h1" text="Subscriptions">
      <template #buttons>
        <Button @click="visitCouponMasterList" text="Coupons" />
        <Button @click="visitReferralCodeMasterList" text="Referral Codes" />
        <Button @click="visitPricesMasterList" text="Prices" />
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :sort-by-options="{
        createdAt: 'Created At',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #name="{ row }: { row: KinvaultSubscription }">
        {{ row.kintin?.friendlyName }}<br />
        {{ row.stripeSubscriptionId }}
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { ReadKinvaultSubscriptionForm } from "@/module/kinvault.subscription/form/read-kinvault-subscription.form";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Equal, In, Like } from "@kinherit/orm/index";
import { IKinvaultSubscription, KinvaultSubscription } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  KinvaultCouponMasterListRoute,
  KinvaultPriceMasterListRoute,
  KinvaultSubscriptionMasterListRoute,
  KinvaultSubscriptionReferralCodeMasterListRoute,
} from ".";

export default defineComponent({
  name: KinvaultSubscriptionMasterListRoute,
  components: { MasterListPage, PageHeader, Button },
  mixins: [
    ActionBusMixin(
      () =>
        window.Kernel.ActionBus2.portal2.kinvaults.subscription.subscription,
    ),
  ],
  data: () => ({
    filters: ReadKinvaultSubscriptionForm(),
    columns: [
      {
        title: "Name",
        slot: "name",
      },
      {
        title: "Referral Code",
        map: (val) => val.referralCode?.code,
      },
      {
        title: "Status",
        map: (val) => val.status.ucFirst().replaceAll("_", " "),
      },
      {
        title: "Created",
        map: (val) => val.createdAt.formatYMD,
      },
    ] as Array<TableColumn<KinvaultSubscription>>,
    rows: Array<KinvaultSubscription>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IKinvaultSubscription,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    handleClick(
      kinvaultSubscription: KinvaultSubscription,
      event: MouseEvent,
    ): void {
      console.log({
        kinvaultSubscription,
        event,
      });
    },
    async refresh(
      formData: ReturnType<typeof ReadKinvaultSubscriptionForm>["localData"],
    ): Promise<void> {
      const data = await this.$actionBus.ReadKinvaultSubscription({
        query: {
          status: Equal(formData.status),
          stripeSubscriptionId: Like(formData.stripeSubscriptionId),
          referralCode: {
            id: In(formData.referralCode),
          },
          kintin: {
            friendlyName: Like(formData.friendlyName),
          },
          createdAt: Between(formData.created),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.kinvaultSubscription;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    visitCouponMasterList(event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultCouponMasterListRoute,
        },
        event.ctrlKey,
      );
    },
    visitReferralCodeMasterList(event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultSubscriptionReferralCodeMasterListRoute,
        },
        event.ctrlKey,
      );
    },
    visitPricesMasterList(event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: KinvaultPriceMasterListRoute,
        },
        event.ctrlKey,
      );
    },
  },
});
</script>
