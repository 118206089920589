import { CreateKinvaultCoupon } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-coupon/create-kinvault-coupon.action";
import { DeleteKinvaultCoupon } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-coupon/delete-kinvault-coupon.action";
import { ReadKinvaultCoupon } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-coupon/read-kinvault-coupon.action";
import { RecordKinvaultCoupon } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-coupon/record-kinvault-coupon.action";
import { UpdateKinvaultCoupon } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-coupon/update-kinvault-coupon.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const Portal2KinvaultSubscriptionCoupon = DefineActionBus({
  name: "portal-2-kinvaults-subscription-coupon",
  actions: {
    ReadKinvaultCoupon,
    RecordKinvaultCoupon,
    DeleteKinvaultCoupon,
    UpdateKinvaultCoupon,
    CreateKinvaultCoupon,
  },
});
