import { CreateKinvaultSubscription } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-subscription/create-kinvault-subscription.action";
import { DeleteKinvaultSubscription } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-subscription/delete-kinvault-subscription.action";
import { ReadKinvaultSubscription } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-subscription/read-kinvault-subscription.action";
import { RecordKinvaultSubscription } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-subscription/record-kinvault-subscription.action";
import { UpdateKinvaultSubscription } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-subscription/update-kinvault-subscription.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const Portal2KinvaultSubscriptionSubscription = DefineActionBus({
  name: "portal-2-kinvaults-subscription-subscription",
  actions: {
    ReadKinvaultSubscription,
    RecordKinvaultSubscription,
    DeleteKinvaultSubscription,
    UpdateKinvaultSubscription,
    CreateKinvaultSubscription,
  },
});
