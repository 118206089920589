import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KinvaultCoupon } from "@kinherit/sdk/index";

export const UpdateKinvaultCoupon = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/coupons/{kinvaultCoupon}",
  method: "patch",
  parse: (message: KinvaultCoupon) => ({
    params: {
      kinvaultCoupon: message,
    },
    body: {
      amountOff: message.$data.amountOff,
      currency: message.$data.currency,
      duration: message.$data.duration,
      durationInMonths: message.$data.durationInMonths,
      maxRedemptions: message.$data.maxRedemptions,
      name: message.$data.name,
      stripePriceId: message.$data.stripeCouponId,
      percentOff: message.$data.percentOff,
    },
  }),
});
