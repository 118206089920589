import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import {
  Between,
  Equal,
  In,
  IsNotNull,
  LessThanOrEqual,
  MoreThanOrEqual,
  Operator,
} from "@kinherit/orm/index";
import { IntroducerFeePaymentItem, Kintin, QueryMask } from "@kinherit/sdk";
import { Order } from "@kinherit/sdk/model/order";
import { DateTime, Time } from "@kinherit/ts-common";

export interface ReadIntroducerFeeKintinMessage {
  referralCode: Array<string>;
  agent: Array<string>;
  period: null | [number, number];
  reconciled: null | boolean;
  xeroContacts: Array<string>;
  onXero: null | "introducer" | "agent" | "parentAgent";
  showOrders: "waitingForPayment" | "within21DayCoolOffPeriod" | "any";
}

export interface ReadKintinIntroducerFeeResponse {
  pagination: { currentPage: number; lastPage: number; count: number };
  data: {
    calculations: {
      agentFee: { fee: number; price: number; selected: number | null };
      introducerFeeNet: number;
      introducerFeeGross: number;
      parentAgentFee: { fee: number; price: number; selected: number | null };
    };
    data: {
      client: {
        ref: string | null;
        name: string | null;
        createdAt: number | null;
        referralCode: string | null;
        kintin: string | null;
      };
      order: {
        discountReason:
          | "other"
          | "introducer"
          | "introfee"
          | "staff"
          | "stafftraining"
          | "closing"
          | "kinheriterror"
          | null;
        id: string;
        createdAt: number;
        paidAt: number | null;
        discountValue: number | null;
        xeroAgentBillId: string | null;
        discountPercentage: number | null;
        totalPrice: number;
        feesConfirmed: boolean | null;
        totalPriceVat: number;
        xeroIntroducerBillId: string | null;
        xeroParentAgentBillId: string | null;
        feesPayoutAgent: number | null;
        feesPayOutAgentDate: number | null;
        feesPayOutIntro: number | null;
        feesPayOutIntroDate: number | null;
        feesPayOutParentAgent: number | null;
        feesPayOutParentAgentDate: number | null;
        invoiceNumber: string | null;
        overrideListPrice: number | null;
        status: string | null;
        introducerFeePaymentRun_id: string | null;
        agentFeePaymentRun_id: string | null;
        parentAgentFeePaymentRun_id: string | null;
      };
      company: {
        id: string | null;
        name: string | null;
        contact: string | null;
        createdAt: number | null;
        kintinXeroContactId: string | null;
        trustRegXeroContactId: string | null;
        network: string | null;
        contractNotes: string | null;
      };
      contact: { id: string | null; name: string | null };
      companyContract: {
        vat: "net" | "n/a" | "gross" | null;
        id: string | null;
        datedAt: number | null;
        type: string | null;
        createdAt: number | null;
        datedTill: number | null;
        i_4c_percent: number | null;
        isSigned: boolean | null;
      };
      parentCompany: {
        id: string | null;
        name: string | null;
        kintinXeroContactId: string | null;
      };
      parentCompanyContract: {
        vat: "net" | "n/a" | "gross" | null;
        id: string | null;
        i_4c_percent: number | null;
      };
      parentAgentCompany: {
        name: string | null;
        id: string | null;
        network: string | null;
        kintinXeroContactId: string | null;
        trustRegXeroContactId: string | null;
      };
      parentAgentContract: {
        vat: "net" | "n/a" | "gross" | null;
        id: string | null;
        createdAt: number | null;
        i_4c_percent: number | null;
        datedAt: number | null;
        datedTill: number | null;
        ai_5a_months: string | null;
        ai_5b_months: string | null;
        ai_5c_months: string | null;
        ai_5a_percent: number | null;
        ai_5b_percent: number | null;
        ai_5c_percent: number | null;
        isSigned: boolean | null;
      };
      agentCompany: {
        id: string | null;
        name: string | null;
        network: string | null;
        kintinXeroContactId: string | null;
      };
      agentContract: {
        vat: "net" | "n/a" | "gross" | null;
        id: string | null;
        datedAt: number | null;
        datedTill: number | null;
        ai_5a_months: string | null;
        ai_5b_months: string | null;
        ai_5c_months: string | null;
        ai_5a_percent: number | null;
        ai_5b_percent: number | null;
        ai_5c_percent: number | null;
        isSigned: boolean | null;
      };
    };
  }[];
}

export const ReadKintinIntroducerFees = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/fees/kintin",
  method: "get",
  rootModel: Order,
  parse: (message: ReadIntroducerFeeKintinMessage) => {
    let paidAt: Operator | undefined = undefined;

    if (message.period) {
      paidAt = Between([
        DateTime.fromDate(new Date(message.period[1], message.period[0] - 1, 1))
          .timestamp,
        DateTime.fromDate(new Date(message.period[1], message.period[0], 1))
          .timestamp,
      ]);
    }

    if (message.showOrders === "waitingForPayment") {
      paidAt = LessThanOrEqual(
        DateTime.fromDate(new Date())
          .sub(Time.fromArray(0, 0, 0, 0, 21))
          .setTime(0, 0, 0, 0).timestamp,
      );
    }

    if (message.showOrders === "within21DayCoolOffPeriod") {
      paidAt = MoreThanOrEqual(
        DateTime.fromDate(new Date())
          .sub(Time.fromArray(0, 0, 0, 0, 21))
          .setTime(0, 0, 0, 0).timestamp,
      );
    }

    let kintin: QueryMask<typeof Kintin> | undefined = undefined;

    if (message.agent.length > 0) {
      kintin = {
        referral: {
          referralCode: {
            company: {
              introducedBy: {
                id: In(message.agent),
              },
            },
          },
        },
      };
    } else {
      kintin = {
        referral: {
          referralCode: {
            id: In(message.referralCode),
          },
        },
      };
    }

    let introducerFeePaymentItems:
      | QueryMask<typeof IntroducerFeePaymentItem>
      | undefined = undefined;

    if (message.xeroContacts.length > 0) {
      introducerFeePaymentItems = {
        introducerFeePayment: {
          xeroContact: {
            id: In(message.xeroContacts),
          },
        },
      };
    }

    return {
      query: {
        kintin,
        paidAt,
        introducerFeePaymentItems,
        feesConfirmed: Equal(message.reconciled),
        xeroIntroducerBillId:
          message.onXero === "introducer" ? IsNotNull() : undefined,
        xeroAgentBillId: message.onXero === "agent" ? IsNotNull() : undefined,
        xeroParentAgentBillId:
          message.onXero === "parentAgent" ? IsNotNull() : undefined,
      },
    };
  },
});
