import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { KinvaultPrice } from "@kinherit/sdk/index";

export const UpdateKinvaultPrice = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/prices/{kinvaultPrice}",
  method: "patch",
  parse: (message: KinvaultPrice) => ({
    params: {
      kinvaultPrice: message,
    },
    body: {
      stripePriceId: message.$data.stripePriceId,
      title: message.$data.title,
      description: message.$data.description,
      price: message.$data.price,
      active: message.$data.active,
    },
  }),
});
