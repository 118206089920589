// Subscription, Kintin, Product

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Subscription } from "@kinherit/sdk/model/subscription";

export const ReadSubscription = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/physical-storage-subscriptions",
  method: "get",
  rootModel: Subscription,
  parse: () => ({}),
});
