import { CreateKinvaultPrice } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-price/create-kinvault-price.action";
import { DeleteKinvaultPrice } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-price/delete-kinvault-price.action";
import { ReadKinvaultPrice } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-price/read-kinvault-price.action";
import { RecordKinvaultPrice } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-price/record-kinvault-price.action";
import { UpdateKinvaultPrice } from "@/action-bus/portal-2/kinvaults/subscription/kinvault-price/update-kinvault-price.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

export const Portal2KinvaultSubscriptionPrice = DefineActionBus({
  name: "portal-2-kinvaults-subscription-price",
  actions: {
    ReadKinvaultPrice,
    RecordKinvaultPrice,
    DeleteKinvaultPrice,
    UpdateKinvaultPrice,
    CreateKinvaultPrice,
  },
});
