// PhysicalStorageFile

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Kintin } from "@kinherit/sdk/model/kintin";
import { PhysicalStorageFile } from "@kinherit/sdk/model/physical-storage-file";
import { Subscription } from "@kinherit/sdk/model/subscription";

export const DeletePhysicalStorageFile = DefineDeleteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/kinvault/{kintin}/physical-storage-subscription/{subscription}/storage-request/{physicalStorageFile}",
  method: "delete",
  parse: (message: {
    kintin: Kintin | string;
    subscription: Subscription | string;
    physicalStorageFile: PhysicalStorageFile | string;
  }) => ({
    params: {
      kintin: message.kintin,
      subscription: message.subscription,
      physicalStorageFile: message.physicalStorageFile,
    },
  }),
  after: ({ message }) => {
    PhysicalStorageFile.$delete(message.physicalStorageFile);
  },
});
