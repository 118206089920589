import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KinvaultPrice } from "@kinherit/sdk/index";

export const DeleteKinvaultPrice = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/prices/{kinvaultPrice}",
  method: "delete",
  parse: (message: string | KinvaultPrice) => ({
    params: {
      kinvaultPrice: message,
    },
  }),
  after: ({ message }) => {
    KinvaultPrice.$delete(message);
  },
});
