import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreatePhysicalStorageFileLog } from "./create-physical-storage-file-log.action";
import { CreatePhysicalStorageFile } from "./create-physical-storage-file.action";
import { CreateSubscription } from "./create-subscription.action";
import { DeletePhysicalStorageFile } from "./delete-physical-storage-file.action";
import { DeleteSubscription } from "./delete-subscription.action";
import { ReadSubscription } from "./read-subscription.action";
import { UpdatePhysicalStorageFile } from "./update-physical-storage-file.action";
import { UploadStorageRequest } from "./upload-storage-request.action";

export const Portal2KinvaultPhysicalStorageSubscription = DefineActionBus({
  name: "portal-2-kinvault-physical-storage-subscription",
  actions: {
    CreateSubscription,
    DeleteSubscription,
    ReadSubscription,
    CreatePhysicalStorageFileLog,
    CreatePhysicalStorageFile,
    DeletePhysicalStorageFile,
    UpdatePhysicalStorageFile,
    UploadStorageRequest,
  },
});
