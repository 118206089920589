import {
  OptionGroup,
  OptionService,
} from "@/module/core/service/option.service";
import { ComponentModelType } from "@kinherit/framework/component.config/component-helpers";
import { FormElement } from "@kinherit/framework/component.form/element";
import {
  AutoCompleteField,
  FormAutoCompleteField,
} from "@kinherit/framework/component.input/auto-complete-field";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import {
  DateRangeField,
  FormDateRangeField,
} from "@kinherit/framework/component.input/date-range-field";
import {
  FormSelectField,
  SelectField,
} from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { FormBuilderComponentOptions } from "@kinherit/framework/form-builder/core/component-wrapper";
import {
  Dictionary,
  DictionaryName,
} from "@kinherit/framework/service/dictionary";
import { Equal, In, Like, Model, NotEqual, NotIn } from "@kinherit/orm";
import {
  AccountReferralCode,
  Address,
  Adviser,
  BrandedKinvault,
  BrandedKinvaultMembership,
  EmailAddress,
  EmailTemplate,
  IntroducerCompany,
  IntroducerContact,
  IntroducerContract,
  IntroducerFeePaymentRun,
  IntroducerNetwork,
  Kintin,
  KinvaultCoupon,
  KinvaultPrice,
  KinvaultSubscriptionReferralCode,
  KnowledgeBaseCategory,
  Option,
  Order,
  Person,
  Product,
  Property,
  QueryMask,
  Role,
  Tag,
  User,
  WillRevision,
  XeroContact,
} from "@kinherit/sdk";
import { Currency, DashLoader, DateTime } from "@kinherit/ts-common";
import { reactive } from "vue";

export const SharedFormProps = {
  size: "is-normal" as const,
  isScrollable: true,
};

export const SharedFilterProps = {
  size: "is-small" as const,
  isScrollable: true,
  autoCollapseLabel: true,
};

const SharedOptionFieldProps: Record<
  OptionGroup,
  ReturnType<typeof FormAutoCompleteField>["props"]
> = {
  lifeTimeGiftTypes: {
    label: "Life Time Gift Type",
  },
  kinvaultStatus: {
    label: "Kinvault Status",
  },
  kintinStatus: {
    label: "Kintin Status",
  },
  kintinTypes: {
    label: "Kintin Types",
  },
  communicationNoteOutcome: {
    label: "Communication Note Outcome",
  },
  process: {
    label: "Process",
  },
  kintinStage: {
    label: "Advised Stage",
  },
  officerCallStatus: {
    label: "Officer Call Status",
  },
  subscriptionStatus: {
    label: "Subscription Status",
  },
  orderStatus: {
    label: "Order Status",
  },
  paymentType: {
    label: "Payment Type",
  },
  leadStatus: {
    label: "Lead Status",
  },
  introducerStatus: {
    label: "Introducer Status",
  },
  introducerType: {
    label: "Introducer Type",
  },
  introducerStage: {
    label: "Introducer Stage",
  },
  introducerContactStatus: {
    label: "Introducer Contact Status",
  },
  introducerContractType: {
    label: "Introducer Contract Type",
  },
  introducerCpdType: {
    label: "Introducer CPD Type",
  },
  trustRegistrationStatus: {
    label: "Trust Registration Status",
  },
  callDirections: {
    label: "Call Directions",
  },
  appointmentType: {
    label: "Appointment Type",
  },
  emailTemplateType: {
    label: "Email Template Type",
  },
  appointmentOutcome: {
    label: "Appointment Outcome",
  },
  classGiftTypes: {
    label: "Class Gift Type",
  },
  businessAssetTypes: {
    label: "Business Asset Type",
  },
  businessTypes: {
    label: "Business Type",
  },
  cashDebtTypes: {
    label: "Cash Debt Type",
  },
  giftTypes: {
    label: "Gift Type",
  },
  investmentTypes: {
    label: "Investment Type",
  },
  otherAssetTypes: {
    label: "Other Asset Type",
  },
  pensionTypes: {
    label: "Pension Type",
  },
  policyTypes: {
    label: "Policy Type",
  },
  policyPayOutTypes: {
    label: "Policy Pay Out Type",
  },
  ownershipTypes: {
    label: "Ownership Type",
  },
  titles: {
    label: "Title",
  },
  howOwnedTypes: {
    label: "How Owned Type",
  },
  propertyTypes: {
    label: "Property Type",
  },
  ownedTypes: {
    label: "Owned Type",
  },
  willReviewCategories: {
    label: "Will Review Category",
  },
  willWriterTypes: {
    label: "Will Writer Type",
  },
  referralSources: {
    label: "Referral Source",
  },
  introducerContractVat: {
    label: "Introducer Contract VAT",
  },
  orderDiscountReason: {
    label: "Order Discount Reason",
  },
  introducerCpdStatus: {
    label: "Introducer CPD Status",
  },
  organisationTypes: {
    label: "Organisation Type",
  },
  clientPoolTypes: {
    label: "Client Pool Type",
  },
  organisationSiteMode: {
    label: "Organisation Site Mode",
  },
  fileLocations: {
    label: "File Location",
  },
  filePolicyTypes: {
    label: "File Policy Type",
  },
  storageProviders: {
    label: "Storage Provider",
  },
  fileTypes: {
    label: "File Type",
  },
  disclaimerClause: {
    label: "Disclaimer Clause",
  },
  disclaimerCondition: {
    label: "Disclaimer Condition",
  },
  ifPredeceasedTypes: {
    label:
      "If Predeceased, this gift to pass to: (Extreme caution if changing from Their Concern)",
  },
  kinvaultTheme: {
    label: "Kinvault Theme",
  },
  kinvaultThemeNavbar: {
    label: "Navbar Type",
  },
  kinvaultThemeFooter: {
    label: "Footer Type",
  },
  kinvaultThemeFooterClass: {
    label: "Footer Class",
  },
  kinvaultThemeHeader: {
    label: "Header Type",
  },
  kinvaultThemeHeaderLogoClass: {
    label: "Header Logo Class",
  },
  kinvaultThemeFooterLogoClass: {
    label: "Footer Logo Class",
  },
  kinvaultThemeLogin: {
    label: "Login Type",
  },
  kinvaultThemeLoginScreenImage: {
    label: "Login Screen Image",
  },
  teamRoles: {
    label: "Team Role",
  },
  kintinAccess: {
    label: "Kintin Access",
  },
  maritalStatuses: {
    label: "Marital Status",
    placeholder: null,
  },
  genders: {
    label: "Gender",
    placeholder: null,
  },
  qualifiedOut: {
    label: "Select Reason:",
  },
  agentRemittanceType: {
    label: "Agent Remittance Type",
  },
  introducerRemittanceType: {
    label: "Introducer Remittance Type",
  },
  notificationStatus: {
    label: "Notification Status",
  },
  signDocType: {
    label: "Sign Doc Type",
  },
  emailTemplateIncludeTo: {
    label: "Include To",
  },
  preloadedEmailAttachments: {
    label: "Preloaded Email Attachments",
  },
  physicalStorageFileLogActions: {
    label: "Action",
  },
  introducerCpdCallStatus: {
    label: "Introducer CPD Call Status",
  },
  relationRelationship: {
    label: "Relationship",
  },
  relationType: {
    label: "Relation Type",
  },
  packingSlipProducts: {
    label: "Packing Slip Products",
  },
  userStatus: {
    label: "User Status",
  },
  introducerOutsourceStatuses: {
    label: "Introducer Outsource Statuses",
  },
  introducerOutsourceTypes: {
    label: "Introducer Outsource Types",
  },
  introducerFeePaymentRunStatus: {
    label: "Introducer Fee Payment Run Status",
  },
  introducerFeePaymentStatus: {
    label: "Introducer Fee Payment Status",
  },
};

export const OptionsAutoCompleteField = <Data>({
  reactive = false,
  vModel,
  group,
  props = {},
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
}: {
  reactive?: boolean;
  vModel: string | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  group: OptionGroup;
  props?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["props"];
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
}): ReturnType<typeof FormAutoCompleteField<Data>> => {
  const options = OptionService.getOptions(group, true);

  return FormAutoCompleteField({
    reactive: reactive,
    models: {
      value:
        "string" === typeof vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(data, vModel.split("."));
                return simplifyData
                  ? Array.isArray(value) && value.length > 0
                    ? options.filter((o) => value.includes(o.id))
                    : options.find((o) => o.id === value)
                  : value;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? Array.isArray(value)
                    ? value.pluck("id")
                    : value?.id
                  : value;
                DashLoader.set(data, vModel.split("."), option);
              },
            }
          : vModel,
    },
    props: {
      ...SharedOptionFieldProps[group],
      ...props,
      options: options,
      mapOptions: {
        value: "id",
        label: optionTitleField ?? "text",
        line1: optionLine1Field ?? "description",
        line2: optionLine2Field,
      },
      reference: "string" === typeof vModel ? vModel : props.reference,
    },
  });
};

export const OptionsSelectField = <Data>({
  vModel,
  group,
  props = {},
  simplifyData = false,
  optionDataFilter = {},
}: {
  vModel: string | ComponentModelType<typeof SelectField, Data>["value"];
  group: OptionGroup;
  props?: ReturnType<typeof FormSelectField<Data>>["props"];
  simplifyData?: boolean;
  optionDataFilter?: Record<string, any>;
}): ReturnType<typeof FormSelectField<Data>> => {
  let options = OptionService.getOptions(group, true);

  if (optionDataFilter && Object.keys(optionDataFilter).length > 0) {
    options = options.filter((option) =>
      Object.entries(optionDataFilter).every(
        ([key, value]) => option.data && option.data[key] === value,
      ),
    );
  }

  return FormSelectField<Data>({
    models: {
      value:
        "string" === typeof vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(data, vModel.split("."));

                if (simplifyData) {
                  return Array.isArray(value) && value.length > 0
                    ? value
                    : options.find((o) => o.id === value);
                } else {
                  return value;
                }
              },
              set: async (value, data) => {
                DashLoader.set(data, vModel.split("."), value);
              },
            }
          : vModel,
    },
    props: {
      placeholder: "Any",
      ...SharedOptionFieldProps[group],
      ...props,
      options: options,
      mapOptions: {
        label: "text",
        value: "id",
      },
      reference: "string" === typeof vModel ? vModel : props.reference,
    },
  });
};

export const AccountNumberField = <Data>({
  props,
}: {
  props: ReturnType<typeof FormTextField<Data>>["props"] & { vModel: string };
}): ReturnType<typeof FormTextField<Data>> =>
  FormTextField({
    models: {
      value: props.vModel,
    },
    props: {
      label: "Account Number",

      ...props,
      validators: [
        ...((props.validators as Array<any>) ?? []),
        [
          "regex",
          {
            regex: "^[0-9]{8,8}$",
            message: "Please enter a valid account number (12345678)",
          },
        ],
      ],
      reference: props.vModel,
    },
  });

export const SortCodeField = <Data>({
  props,
}: {
  props: ReturnType<typeof FormTextField<Data>>["props"] & { vModel: string };
}): ReturnType<typeof FormTextField<Data>> =>
  FormTextField({
    models: {
      value: props.vModel,
    },
    props: {
      label: "Sort Code",

      ...props,
      validators: [
        ...((props.validators as Array<any>) ?? []),
        [
          "regex",
          {
            regex: "^[0-9]{6,6}$",
            message: "Please enter a valid sort code (123456)",
          },
        ],
      ],
      reference: props.vModel,
    },
  });

export const IBANField = <Data>({
  props,
}: {
  props: ReturnType<typeof FormTextField<Data>>["props"] & { vModel: string };
}): ReturnType<typeof FormTextField<Data>> =>
  FormTextField<Data>({
    models: {
      value: props.vModel,
    },
    props: {
      label: "IBAN",

      ...props,
      validators: [
        ...((props.validators as Array<any>) ?? []),
        [
          "regex",
          {
            regex: "^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,3}$",
            message: "Please enter a valid IBAN (GB12341234123412341234)",
          },
        ],
      ],
      reference: props.vModel,
    },
  });

export const DictionaryField = <Data>({
  dictionary,
  props,
}: {
  dictionary: DictionaryName;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel: string;
  };
}): ReturnType<typeof FormAutoCompleteField<Data>> => {
  const options = Object.entries(
    Dictionary.map([dictionary])[dictionary](),
  ).map(([key, value]) => ({
    value: key,
    text: value,
  }));

  return FormAutoCompleteField<Data>({
    models: {
      value: {
        get: (data) => {
          const value = DashLoader.get(data, props.vModel.split("."));

          return Array.isArray(value)
            ? options.filter((option) => value.includes(option.value))
            : options.find((option) => value === option.value);
        },
        set: (option, data) => {
          DashLoader.set(data, props.vModel.split("."), option?.value || null);
        },
      },
    },
    props: {
      placeholder: "Any",
      ...props,
      options,
      mapOptions: {
        value: "value",
        label: "text",
      },
      reference: props.vModel,
    },
  });
};

export const RoleField = <Data>({
  props,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  reactive,
}: {
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  reactive?: boolean;
}): ReturnType<typeof FormAutoCompleteField<Data>> => {
  const options =
    window.Kernel.Mode === KernelModes.Dev
      ? Role.$all().sortBy("role")
      : Role.$findBy({
          role: NotEqual("admin"),
        }).sortBy("role");

  return FormAutoCompleteField<Data>({
    reactive: reactive,
    models: {
      value:
        "string" === typeof props.vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(
                  data,
                  (props.vModel as string).split("."),
                );
                return simplifyData
                  ? Array.isArray(value)
                    ? options.filter((option) => value.includes(option.id))
                    : options.find((option) => value === option.id)
                  : value;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? Array.isArray(value)
                    ? value.pluck("id")
                    : value?.id
                  : value;
                DashLoader.set(
                  data,
                  (props.vModel as string).split("."),
                  option,
                );
              },
            }
          : props.vModel,
    },
    props: {
      label: "Role",
      ...props,
      options,
      mapOptions: {
        value: "id",
        label: optionTitleField ?? "name",
        line1: optionLine1Field,
        line2: optionLine2Field,
      },
      reference:
        "string" === typeof props.vModel ? props.vModel : props.reference,
    },
  });
};

export const CreatedAtField = <Data>({
  props,
  simplifyData = false,
}: {
  props: ReturnType<typeof FormDateRangeField<Data>>["props"] & {
    vModel: string | ComponentModelType<typeof DateRangeField, Data>["value"];
  };
  simplifyData?: boolean;
}): ReturnType<typeof FormDateRangeField<Data>> => {
  return FormDateRangeField<Data>({
    models: {
      value:
        "string" === typeof props.vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(
                  data,
                  (props.vModel as string).split("."),
                );
                return simplifyData
                  ? value
                    ? [
                        new DateTime(Number.parseInt(value[0].toString())),
                        new DateTime(Number.parseInt(value[1].toString())),
                      ]
                    : undefined
                  : null;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? value
                    ? [value[0].timestamp, value[1].timestamp]
                    : undefined
                  : value;
                DashLoader.set(
                  data,
                  (props.vModel as string).split("."),
                  option,
                );
              },
            }
          : props.vModel,
    },
    props: {
      placeholder: "Any",
      label: "Created",
      ...props,
      reference:
        "string" === typeof props.vModel ? props.vModel : props.reference,
    },
  });
};

export const TagsField = <Data>({
  props,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
}: {
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
}): ReturnType<typeof FormAutoCompleteField<Data>> => {
  const options = Tag.$all().sortBy("name");

  return FormAutoCompleteField<Data>({
    models: {
      value:
        "string" === typeof props.vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(
                  data,
                  (props.vModel as string).split("."),
                );
                return simplifyData
                  ? Array.isArray(value)
                    ? options.filter((option) => value.includes(option.id))
                    : options.find((option) => value === option.id)
                  : value;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? Array.isArray(value)
                    ? value.pluck("id")
                    : value?.id
                  : value;
                DashLoader.set(
                  data,
                  (props.vModel as string).split("."),
                  option,
                );
              },
            }
          : props.vModel,
    },
    props: {
      label: "Tags",
      ...props,
      options,
      mapOptions: {
        value: "id",
        label: optionTitleField ?? "name",
        line1: optionLine1Field,
        line2: optionLine2Field,
      },
      reference:
        "string" === typeof props.vModel ? props.vModel : props.reference,
    },
  });
};

const GenericModelField = <Data, Entity extends new (data: any) => Model<any>>({
  reactive = false,
  selectField = false,
  model,
  props,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  asyncLoad,
  asyncFilter,
  slots,
}: {
  reactive?: boolean;
  selectField?: boolean;
  model: Entity;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  simplifyData?: boolean;
  optionTitleField: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  asyncFilter?: (search?: string | null) => Promise<any[]>;
  asyncLoad?: (options: any[]) => Promise<any[]>;
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return (selectField ? FormSelectField : FormAutoCompleteField)({
    reactive: reactive,
    models: {
      value:
        "string" === typeof props.vModel
          ? {
              get: (data) => {
                const value = DashLoader.get(
                  data,
                  (props.vModel as string).split("."),
                );

                if (Array.isArray(value) && value.length === 0) {
                  return [];
                }

                return simplifyData
                  ? Array.isArray(value)
                    ? (model as any).$findBy({
                        id: In(value),
                      })
                    : (model as any).$findOne(value)
                  : value;
              },
              set: async (value, data) => {
                const option = simplifyData
                  ? Array.isArray(value)
                    ? value.pluck("id")
                    : value?.id
                  : value;
                DashLoader.set(
                  data,
                  (props.vModel as string).split("."),
                  option,
                );
              },
            }
          : props.vModel,
    },
    props: {
      label: model.name.toSentenceCase(),
      asyncLoad: asyncLoad ? () => asyncLoad : undefined,
      ...props,
      options: props.options ?? [],
      asyncFilter:
        (props.options?.length ?? 0) > 0 ? undefined : () => asyncFilter,
      mapOptions: {
        value: "id",
        line1: optionLine1Field,
        label: optionTitleField,
        line2: (optionLine2Field ? optionLine2Field : undefined) as never,
      },
      reference:
        "string" === typeof props.vModel ? props.vModel : props.reference,
    },
    slots,
  });
};

export const EmailAddressField = <Data>({
  selectField,
  props,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  options,
  slots,
  query,
  limit = 20,
}: {
  selectField?: boolean;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
  limit?: number;
} & (
  | {
      options: EmailAddress[];
      query?: undefined;
    }
  | {
      query: QueryMask<typeof EmailAddress>;
      options?: undefined;
    }
)): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return GenericModelField({
    selectField,
    slots,
    model: EmailAddress,
    simplifyData,
    optionTitleField:
      optionTitleField ??
      ((emailAddress: EmailAddress) => {
        let title = ``;

        if (emailAddress.profile?.firstName || emailAddress.profile?.lastName) {
          title += [
            emailAddress.profile?.firstName,
            emailAddress.profile?.lastName,
          ]
            .filter(Boolean)
            .join(" ");
        } else if (emailAddress.profile?.organisationName) {
          title += emailAddress.profile.organisationName;
        }

        return `` !== title
          ? `${title} <${emailAddress.email}>`
          : emailAddress.email;
      }),
    optionLine1Field,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }

      //        await window.Kernel.ActionBus.core.select.emailAddress.read(

      const { emailAddress } =
        await window.Kernel.ActionBus2.core.select.ReadEmailAddress(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return emailAddress;
    },
    asyncFilter: options
      ? undefined
      : async (search?: string | null) => {
          if (!query) {
            query = {};
          }

          if (![null, "", undefined].includes(search)) {
            query = {
              ...query,
              profile: {
                ...((query as any).profile ?? {}),
                fullName: Like(search),
              },
            };
          } else {
            query = {
              ...query,
              profile: {
                ...((query as any).profile ?? {}),
                fullName: null,
              },
            };
          }
          //           await window.Kernel.ActionBus.execute(

          const { emailAddress: options } =
            await window.Kernel.ActionBus2.core.select.ReadEmailAddress(
              {
                query,
                pagination:
                  null === limit || true === selectField
                    ? false
                    : {
                        currentPage: 0,
                        perPage: limit,
                      },
              },
              {
                hideLoading: true,
              },
            );

          return options;
        },
    props: {
      ...props,
      options: options,
    },
  });
};

export const AddressField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Address>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    model: Address,
    props: {
      label: "Address",
      ...props,
    },
    simplifyData,
    optionTitleField: optionTitleField ?? "summary",
    optionLine1Field,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      // await window.Kernel.ActionBus.core.select.address.read(
      const { address } =
        await window.Kernel.ActionBus2.core.select.ReadAddress(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return address;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, { summary: Like(search as string) });
      } else {
        Object.assign(query, { summary: null });
      }
      //      await window.Kernel.ActionBus.core.select.address.read(
      const { address: options } =
        await window.Kernel.ActionBus2.core.select.ReadAddress(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const AdviserField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Adviser>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: Adviser,
    optionTitleField: optionTitleField ?? "profile.fullName",
    optionLine1Field,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      // await window.Kernel.ActionBus.core.select.adviser.read(
      const { adviser } =
        await window.Kernel.ActionBus2.core.select.ReadAdviser(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return adviser;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          person: {
            profile: { fullName: Like(search as string) },
          },
        });
      } else {
        Object.assign(query, {
          person: {
            profile: {
              ...((query as any).profile ?? {}),
              fullName: null,
            },
          },
        });
      }
      // await window.Kernel.ActionBus.core.select.adviser.read(
      const { adviser: options } =
        await window.Kernel.ActionBus2.core.select.ReadAdviser(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const BrandedKinvaultField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof BrandedKinvault>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: BrandedKinvault,
    optionTitleField: optionTitleField ?? "profile.organisationName",
    optionLine1Field,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //      await window.Kernel.ActionBus.core.select.brandedKinvault.read(
      const { brandedKinvault } =
        await window.Kernel.ActionBus2.core.select.ReadBrandedKinvault(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return brandedKinvault;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: { organisationName: Like(search as string) },
        });
      } else {
        Object.assign(query, {
          profile: {
            ...((query as any).profile ?? {}),
            organisationName: null,
          },
        });
      }
      //await window.Kernel.ActionBus.core.select.brandedKinvault.read(
      const { brandedKinvault: options } =
        await window.Kernel.ActionBus2.core.select.ReadBrandedKinvault(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const AccountReferralCodeField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof AccountReferralCode>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: AccountReferralCode,
    optionTitleField:
      optionTitleField ??
      ((item: AccountReferralCode) =>
        [item.name, item.code].filter(Boolean).join(" - ")),
    optionLine1Field,
    optionLine2Field:
      optionLine2Field ??
      ((item: AccountReferralCode) => {
        const company = item.company;

        if (!company) {
          return undefined;
        }

        return (
          company.profile?.organisationName ??
          company.profile?.fullName ??
          undefined
        );
      }),
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //await window.Kernel.ActionBus.core.select.referralCode.read(
      const { accountReferralCode } =
        await window.Kernel.ActionBus2.core.select.ReadAccountReferralCode(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return accountReferralCode;
    },
    asyncFilter: async (search?: string | null) => {
      let newQuery: any = null;
      if (Array.isArray(query)) {
        newQuery = [{ ...query }, { ...query }];
      } else if (query) {
        newQuery = [{ ...query }, { ...query }];
      } else {
        newQuery = [];
      }

      if (![null, "", undefined].includes(search)) {
        newQuery[0] = {
          ...(newQuery[0] || {}),
          code: Like(search as string),
        };

        newQuery[1] = {
          ...(newQuery[1] || {}),
          name: Like(search as string),
        };
      } else {
        newQuery[0] = {
          ...(newQuery[0] || {}),
          code: null,
        };

        newQuery[1] = {
          ...(newQuery[1] || {}),
          name: null,
        };
      }
      //await window.Kernel.ActionBus.core.select.referralCode.read(
      const { accountReferralCode: options } =
        await window.Kernel.ActionBus2.core.select.ReadAccountReferralCode(
          {
            query: newQuery,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const IntroducerContractField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof IntroducerContract>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: IntroducerContract,
    optionTitleField: optionTitleField ?? "name",

    optionLine1Field,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      // await window.Kernel.ActionBus.core.select.introducerContract.read(
      const { introducerContract } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerContract(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return introducerContract;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      } else {
        Object.assign(query, {
          name: null,
        });
      }
      //await window.Kernel.ActionBus.core.select.introducerContract.read(
      const { introducerContract: options } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerContract(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const KnowledgeBaseCategoryField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof KnowledgeBaseCategory>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: KnowledgeBaseCategory,
    optionTitleField: optionTitleField ?? "title",
    optionLine2Field,
    optionLine1Field: optionLine1Field ?? "slug",
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //await window.Kernel.ActionBus.core.select.knowledgeBaseCategory.read(
      const { knowledgeBaseCategory } =
        await window.Kernel.ActionBus2.core.select.ReadKnowledgeBaseCategory(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return knowledgeBaseCategory;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          title: Like(search as string),
        });
      } else {
        Object.assign(query, {
          title: null,
        });
      }
      //      await window.Kernel.ActionBus.core.select.knowledgeBaseCategory.read(
      const { knowledgeBaseCategory: options } =
        await window.Kernel.ActionBus2.core.select.ReadKnowledgeBaseCategory(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const IntroducerContactField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof IntroducerContact>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: IntroducerContact,
    optionTitleField: optionTitleField ?? "profile.fullName",
    optionLine1Field:
      optionLine1Field ?? "companies.0.profile.organisationName",
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //await window.Kernel.ActionBus.core.select.introducerContact.read(
      const { introducerContact } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerContact(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return introducerContact;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: {
            fullName: Like(search as string),
          },
        });
      } else {
        Object.assign(query, {
          profile: {
            ...((query as any).profile ?? {}),
            fullName: null,
          },
        });
      }
      //await window.Kernel.ActionBus.core.select.introducerContact.read(
      const { introducerContact: options } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerContact(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const IntroducerCompanyField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof IntroducerCompany>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: IntroducerCompany,
    optionTitleField: optionTitleField ?? "profile.fullName",
    optionLine1Field,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //await window.Kernel.ActionBus.core.select.introducerCompany.read(
      const { introducerCompany } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerCompany(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return introducerCompany;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: {
            fullName: Like(search as string),
          },
        });
      } else {
        Object.assign(query, {
          profile: {
            ...((query as any).profile ?? {}),
            fullName: null,
          },
        });
      }
      //await window.Kernel.ActionBus.core.select.introducerCompany.read(
      const { introducerCompany: options } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerCompany(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const ProductField = <Data>({
  reactive: isReactive = false,
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
  productDate,
}: {
  reactive?: boolean;
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Product>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
  productDate?: DateTime;
}): ReturnType<typeof FormElement<Data>> => {
  const data = reactive({
    date: productDate ?? null,
    showDateField: false,
  });

  return FormElement({
    slots: {
      default: [
        FormDateField({
          props: {
            vIf: () => data.showDateField,
            label: "Product Date",
          },
          models: {
            value: {
              get: () => data.date,
              set: (value) => {
                data.date = value;
              },
            },
          },
          slots: {
            right: [
              FormButton({
                props: {
                  iconLeft: "Tick",
                  color: "is-success",
                  ariaLabel: "Done",
                },
                emits: {
                  click: (_v, _d, controls) => {
                    data.showDateField = false;
                    controls.rebuildTemplateBindings();
                    controls.incrementFormRenderKey();
                  },
                },
              }),
            ],
          },
        }),
        FormElement({
          props: {
            vIf: () => !data.showDateField,
          },
          slots: {
            default: [
              GenericModelField({
                reactive: isReactive,
                selectField,
                slots,
                props,
                simplifyData,
                model: Product,
                optionTitleField:
                  optionTitleField ??
                  ((item: Product) =>
                    `${item.publicText} (${item.price.format})`),
                optionLine1Field,
                optionLine2Field,
                asyncLoad: async (options) => {
                  if (!options.map((v) => typeof v).onlyIncludes("string")) {
                    return options;
                  }

                  if (options.length === 0) {
                    return options;
                  }

                  //await window.Kernel.ActionBus.core.select.product.read(
                  const { product } =
                    await window.Kernel.ActionBus2.core.select.ReadProduct(
                      {
                        message: {
                          date: data.date as DateTime,
                        },
                        query: {
                          id: In(options),
                        },
                        pagination:
                          null === limit || true === selectField
                            ? false
                            : {
                                currentPage: 0,
                                perPage: limit,
                              },
                      },
                      {
                        hideLoading: true,
                      },
                    );

                  return product;
                },
                asyncFilter: async (search?: string | null) => {
                  if (!query) {
                    query = {};
                  }

                  if (![null, "", undefined].includes(search)) {
                    Object.assign(query, {
                      publicText: Like(search as string),
                    });
                  } else {
                    Object.assign(query, {
                      publicText: null,
                    });
                  }
                  // await window.Kernel.ActionBus.core.select.product.read(
                  const { product: options } =
                    await window.Kernel.ActionBus2.core.select.ReadProduct(
                      {
                        message: {
                          date: data.date as DateTime,
                        },
                        query,
                        pagination:
                          null === limit || true === selectField
                            ? false
                            : {
                                currentPage: 0,
                                perPage: limit,
                              },
                      },
                      {
                        hideLoading: true,
                      },
                    );

                  const orderedProducts = [
                    "standardwill",
                    "assetprotection",
                    "lpa",
                    "lpahw",
                    "lpapf",
                    "dos",
                    "businesstrust",
                    "businessclauses",
                    "lpabusiness",
                  ];
                  return options.sort((a, b) => {
                    const aIndex = orderedProducts.indexOf(a.value);
                    const bIndex = orderedProducts.indexOf(b.value);
                    if (aIndex === -1 && bIndex === -1) {
                      return a.publicText.localeCompare(b.publicText);
                    }
                    if (aIndex === -1) {
                      return 1;
                    }
                    if (bIndex === -1) {
                      return -1;
                    }
                    return aIndex - bIndex;
                  });
                },
              }),
            ],
          },
        }),
        FormElement({
          props: {
            vIf: () => null !== data.date && false === data.showDateField,
            text: () => `Change Date (${data.date?.formatDate})`,
            class: "has-text-info is-underlined is-clickable",
          },
          emits: {
            click: (_v, _d, controls) => {
              data.showDateField = true;
              controls.rebuildTemplateBindings();
              controls.incrementFormRenderKey();
            },
          },
        }),
      ],
    },
  });
};

export const WillRevisionField = <Data>({
  reactive = false,
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
}: {
  reactive?: boolean;
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof WillRevision>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: WillRevision) => string | undefined);
  optionLine2Field?: string | ((item: WillRevision) => string | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return GenericModelField({
    reactive,
    selectField,
    slots,
    props: {
      ...props,
      sort: () => (a: WillRevision, b: WillRevision) => {
        if (a.createdAt.isBefore(b.createdAt)) {
          return 1;
        }

        if (a.createdAt.isAfter(b.createdAt)) {
          return -1;
        }

        return 0;
      },
    },
    simplifyData,
    model: WillRevision,
    optionTitleField:
      optionTitleField ??
      ((item: WillRevision) =>
        `${item.createdAt.formatDateTime} ${
          item.approvedAt
            ? "(Approved by " + item.approvedBy?.profile?.fullName + ")"
            : "(Awaiting approval)"
        }`),
    optionLine1Field:
      optionLine1Field ??
      ((item: WillRevision) => `${item.notes ?? "No Notes available"}`),
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //await window.Kernel.ActionBus.core.select.willRevision.read(
      const { willRevision } =
        await window.Kernel.ActionBus2.core.select.ReadWillRevision(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return willRevision;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          notes: Like(search as string),
        });
      } else {
        Object.assign(query, {
          notes: null,
        });
      }
      //await window.Kernel.ActionBus.core.select.willRevision.read(
      const { willRevision: options } =
        await window.Kernel.ActionBus2.core.select.ReadWillRevision(
          {
            query: query ?? {},
            pagination: false,
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });
};

export const BrandedKinvaultMembershipField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof BrandedKinvaultMembership>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: BrandedKinvaultMembership,
    optionTitleField: optionTitleField ?? "user.profile.fullName",
    optionLine1Field,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //      await window.Kernel.ActionBus.core.select.brandedKinvaultMembership.read(
      const { brandedKinvaultMembership } =
        await window.Kernel.ActionBus2.core.select.ReadBrandedKinvaultMembership(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return brandedKinvaultMembership;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          user: {
            profile: {
              fullName: Like(search as string),
            },
          },
        });
      } else {
        Object.assign(query, {
          user: {
            ...((query as any).user ?? {}),
            profile: {
              ...(((query as any).user ?? {}).profile ?? {}),
              fullName: null,
            },
          },
        });
      }
      //await window.Kernel.ActionBus.core.select.brandedKinvaultMembership.read(
      const { brandedKinvaultMembership: options } =
        await window.Kernel.ActionBus2.core.select.ReadBrandedKinvaultMembership(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const KintinField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Kintin>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: Kintin,
    optionTitleField: optionTitleField ?? "friendlyName",
    optionLine1Field: optionLine1Field ?? "ref",
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //const { kintins } = await window.Kernel.ActionBus.core.select.kintin.read(
      const { kintin } = await window.Kernel.ActionBus2.core.select.ReadKintin(
        {
          query: {
            id: In(options),
          },
        },
        {
          hideLoading: true,
        },
      );

      return kintin;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          friendlyName: Like(search as string),
        });
      } else {
        Object.assign(query, {
          friendlyName: null,
        });
      }
      //await window.Kernel.ActionBus.core.select.kintin.read(
      const { kintin: options } =
        await window.Kernel.ActionBus2.core.select.ReadKintin(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const PersonField = <Data>({
  selectField,
  reactive,
  props,
  query = {},
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  reactive?: boolean;
  query?: QueryMask<typeof Person>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    reactive,
    selectField,
    slots,
    props,
    simplifyData,
    model: Person,
    optionTitleField: optionTitleField ?? "profile.fullName",
    optionLine1Field:
      optionLine1Field ??
      ((p: Person) => {
        const relationToPrimaryPerson = p.relationToPrimaryPerson?.text;
        const primaryPersonProfile = p.kintin?.primaryPerson?.profile;
        const primaryPerson = [
          primaryPersonProfile?.firstName,
          primaryPersonProfile?.lastName,
        ]
          .filter(Boolean)
          .join(" ");

        if (relationToPrimaryPerson && primaryPerson) {
          return `${primaryPerson}'s ${relationToPrimaryPerson}`;
        }

        if (relationToPrimaryPerson) {
          return `Primary Person's ${relationToPrimaryPerson}`;
        }

        return null;
      }),
    optionLine2Field:
      optionLine2Field ??
      ((p: Person) => {
        const relationToSecondaryPerson = p.relationToSecondaryPerson?.text;
        const secondaryPersonProfile = p.kintin?.secondaryPerson?.profile;
        const secondaryPerson = [
          secondaryPersonProfile?.firstName,
          secondaryPersonProfile?.lastName,
        ]
          .filter(Boolean)
          .join(" ");

        if (relationToSecondaryPerson && secondaryPerson) {
          return `${secondaryPerson}'s ${relationToSecondaryPerson}`;
        }

        if (relationToSecondaryPerson) {
          return `Secondary Person's ${relationToSecondaryPerson}`;
        }

        return null;
      }),
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //       const { people } = await window.Kernel.ActionBus.core.select.person.read(
      const { person } = await window.Kernel.ActionBus2.core.select.ReadPerson(
        {
          query: {
            id: In(options),
          },
        },
        {
          hideLoading: true,
        },
      );

      return person;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          profile: { fullName: Like(search as string) },
        });
      } else {
        Object.assign(query, {
          profile: {
            ...((query as any).profile ?? {}),
            fullName: null,
          },
        });
      }
      // await window.Kernel.ActionBus.core.select.person.read(
      const { person: options } =
        await window.Kernel.ActionBus2.core.select.ReadPerson(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const KintinAddressField = <Data>({
  selectField,
  props,
  kintin,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
  exclude,
  unique,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  kintin: string;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
  exclude?: Address[];
  unique?: boolean;
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: Address,
    optionTitleField: optionTitleField ?? "summary",
    optionLine1Field: optionLine1Field
      ? optionLine1Field
      : !unique
      ? "profile.fullName"
      : undefined,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      // await window.Kernel.ActionBus.core.select.address.kintin.read(
      const { address } =
        await window.Kernel.ActionBus2.core.select.ReadAddress(
          {
            query: {
              id: In(options),
              profile: {
                person: {
                  kintin: {
                    id: Equal(kintin),
                  },
                },
              },
            },
          },
          {
            hideLoading: true,
          },
        );

      return address;
    },
    asyncFilter: async (search?: string | null) => {
      //await window.Kernel.ActionBus.core.select.address.kintin.read(
      const { address: options } =
        await window.Kernel.ActionBus2.core.select.ReadAddress(
          {
            query: {
              summary: Like(search as string),
              id: NotIn(exclude?.pluck("id")),
              profile: {
                person: {
                  kintin: {
                    id: Equal(kintin),
                  },
                },
              },
            },
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      if (true === unique) {
        return options.unique("summary");
      }

      return options;
    },
  });

export const UserField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof User>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: User,
    optionTitleField: optionTitleField ?? "profile.fullName",
    optionLine2Field,
    optionLine1Field: optionLine1Field ?? "primaryEmailAddress.email",
    asyncLoad: async (value: (string | { $id: string })[]) => {
      if (!value.map((v) => typeof v).onlyIncludes("string")) {
        return value;
      }
      //await window.Kernel.ActionBus.core.select.user.read(
      const { user: options } =
        await window.Kernel.ActionBus2.core.select.ReadUser(
          {
            query: {
              id: In(value as string[]),
            },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
    asyncFilter: async (search?: string | null) => {
      let newQuery: any = null;
      if (Array.isArray(query)) {
        newQuery = [{ ...query }, { ...query }];
      } else if (query) {
        newQuery = [{ ...query }, { ...query }];
      } else {
        newQuery = [];
      }

      if (![null, "", undefined].includes(search)) {
        newQuery[0] = {
          ...(newQuery[0] || {}),
          profile: { fullName: Like(search as string) },
        };

        newQuery[1] = {
          ...(newQuery[1] || {}),
          profile: { emails: { email: Like(search as string) } },
        };
      } else {
        newQuery[0] = {
          ...(newQuery[0] || {}),
          profile: {
            fullName: null,
          },
        };

        newQuery[1] = {
          ...(newQuery[1] || {}),
          profile: { emails: { email: null } },
        };
      }
      //await window.Kernel.ActionBus.core.select.user.read(
      const { user: options } =
        await window.Kernel.ActionBus2.core.select.ReadUser(
          {
            query: newQuery,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const EmailTemplateField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number | null;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof EmailTemplate>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: EmailTemplate,
    optionTitleField: optionTitleField ?? "name",
    optionLine1Field:
      optionLine1Field ??
      ((item: EmailTemplate) =>
        [
          item.$data.allowAttachments ? "✓ Attachments" : null,
          item.$data.strictAttachments ? "✓ Strict" : null,
        ]
          .filter(Boolean)
          .join(", ") || undefined),
    optionLine2Field:
      optionLine2Field ??
      ((item: EmailTemplate) =>
        [
          item.$data.preloadedFiles.length > 0
            ? `${item.$data.preloadedFiles.length} Preloaded Files`
            : null,
          item.$data.requiredAttachments.length > 0
            ? `${item.$data.requiredAttachments.length} Required Attachments`
            : null,
        ]
          .filter(Boolean)
          .join(", ") || undefined),
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //await window.Kernel.ActionBus.core.select.emailTemplate.read(
      const { emailTemplate } =
        await window.Kernel.ActionBus2.core.select.ReadEmailTemplate(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return emailTemplate;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      } else {
        Object.assign(query, {
          name: null,
        });
      }
      //await window.Kernel.ActionBus.core.select.emailTemplate.read(
      const { emailTemplate: options } =
        await window.Kernel.ActionBus2.core.select.ReadEmailTemplate(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      options.sort((a, b) => a.name.localeCompare(b.name));
      return options;
    },
  });

export const IntroducerNetworkField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof IntroducerNetwork>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: IntroducerNetwork,
    optionTitleField:
      optionTitleField ??
      ((item: IntroducerNetwork) => {
        return item.name;
      }),
    optionLine1Field,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //await window.Kernel.ActionBus.core.select.introducerNetwork.read(
      const { introducerNetwork } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerNetwork(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return introducerNetwork;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      } else {
        Object.assign(query, {
          name: null,
        });
      }
      // await window.Kernel.ActionBus.core.select.introducerNetwork.read(
      const { introducerNetwork: options } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerNetwork(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const PropertyField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Property>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> =>
  GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: Property,
    optionTitleField:
      optionTitleField ??
      ((item: Property) => {
        const { address, titleDeed, type } = item;

        if (address) {
          const { summary } = address;

          if (summary) {
            return summary;
          }
        }

        if (titleDeed) {
          return titleDeed;
        }

        return type.text;
      }),
    optionLine1Field,
    optionLine2Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //await window.Kernel.ActionBus.core.select.property.read(
      const { property } =
        await window.Kernel.ActionBus2.core.select.ReadProperty(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return property;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      } else {
        Object.assign(query, {
          name: null,
        });
      }
      //await window.Kernel.ActionBus.core.select.property.read(
      const { property: options } =
        await window.Kernel.ActionBus2.core.select.ReadProperty(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });

export const OrderField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof Order>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: Order) => string | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: Order,
    optionTitleField:
      optionTitleField ??
      ((item: Order) => {
        return `${
          item.createdAt.formatDateTime
        } (${item.totalPrice.toFormattedString()})`;
      }),
    optionLine1Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //const { orders } = await window.Kernel.ActionBus.core.select.order.read(
      const { order } = await window.Kernel.ActionBus2.core.select.ReadOrder(
        {
          query: {
            id: In(options),
          },
        },
        {
          hideLoading: true,
        },
      );

      return order;
    },
    asyncFilter: async () => {
      //      await window.Kernel.ActionBus.core.select.order.read(
      const { order: options } =
        await window.Kernel.ActionBus2.core.select.ReadOrder(
          {
            query: query ?? {},
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });
};

export const IntroducerFeePaymentRunField = <Data>({
  reactive,
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  slots,
  limit = 20,
}: {
  reactive?: boolean;
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof IntroducerFeePaymentRun>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?:
    | string
    | ((item: IntroducerFeePaymentRun) => string | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return GenericModelField({
    reactive,
    selectField,
    slots,
    props,
    simplifyData,
    model: IntroducerFeePaymentRun,
    optionTitleField:
      optionTitleField ??
      ((item: IntroducerFeePaymentRun) => {
        return `${item.name} (${
          {
            introducerFee: "Introducer",
            agentFee: "Agent",
            parentAgentFee: "Parent Agent",
          }[item.feeTypeId]
        }|items: ${item.introducerFeePaymentsCount})`;
      }),
    optionLine1Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      //      await window.Kernel.ActionBus.core.select.introducerFeePaymentRun.read(
      const { introducerFeePaymentRun } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerFeePaymentRun(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return introducerFeePaymentRun;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      }
      //      await window.Kernel.ActionBus.core.select.introducerFeePaymentRun.read(
      const { introducerFeePaymentRun: options } =
        await window.Kernel.ActionBus2.core.select.ReadIntroducerFeePaymentRun(
          {
            query: query ?? {},
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });
};

export const KintinTypeField = <Data>({
  reactive,
  selectField,
  props,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  optionLine2Field,
}: {
  reactive?: boolean;
  selectField?: boolean;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField | typeof SelectField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<
          typeof AutoCompleteField | typeof SelectField,
          Data
        >["value"];
  };
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: any) => string | null | undefined);
  optionLine2Field?: string | ((item: any) => string | null | undefined);
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  const options = Option.$findBy({
    group: "kintinTypes",
  });

  return GenericModelField({
    reactive,
    selectField,
    props: {
      ...props,
      options,
    },
    simplifyData,
    optionTitleField:
      optionTitleField ?? ((option: Option) => option.value.ucFirst()),
    optionLine1Field,
    optionLine2Field,
    model: Option,
  });
};

export const XeroContactField = <Data>({
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  slots,
  limit = 20,
}: {
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof XeroContact>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: XeroContact) => string | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return GenericModelField({
    selectField,
    slots,
    props,
    simplifyData,
    model: XeroContact,
    optionTitleField:
      optionTitleField ??
      ((item: XeroContact) => {
        return item.contactName ?? item.xeroContactId ?? item.id;
      }),
    optionLine1Field:
      optionTitleField ??
      ((item: XeroContact) => {
        return item.xeroAccountNumber ?? null;
      }),
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      // await window.Kernel.ActionBus.core.select.xeroContact.read(
      const { xeroContact } =
        await window.Kernel.ActionBus2.core.select.ReadXeroContact(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return xeroContact;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          contactName: Like(search as string),
        });
      } else {
        Object.assign(query, {
          contactName: null,
        });
      }
      //      await window.Kernel.ActionBus.core.select.xeroContact.read(
      const { xeroContact: options } =
        await window.Kernel.ActionBus2.core.select.ReadXeroContact(
          {
            query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });
};

// KinvaultPrice
export const KinvaultPriceField = <Data>({
  reactive,
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  slots,
  limit = 20,
}: {
  reactive?: boolean;
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof KinvaultPrice>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: KinvaultPrice) => string | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return GenericModelField({
    reactive,
    selectField,
    slots,
    props,
    simplifyData,
    model: KinvaultPrice,
    optionTitleField:
      optionTitleField ??
      ((item: KinvaultPrice) => {
        return `${item.title} (${item.price?.toFormattedString() ?? "N/A"})`;
      }),
    optionLine1Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      const { kinvaultPrice } =
        await window.Kernel.ActionBus2.core.select.ReadKinvaultPrice(
          {
            query: {
              id: In(options),
              active: Equal(true),
            },
          },
          {
            hideLoading: true,
          },
        );

      return kinvaultPrice;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {
          active: Equal(true),
        };
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      }
      const { kinvaultPrice: options } =
        await window.Kernel.ActionBus2.core.select.ReadKinvaultPrice(
          {
            query: query,
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });
};
// KinvaultCoupon
export const KinvaultCouponField = <Data>({
  reactive,
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  slots,
  limit = 20,
}: {
  reactive?: boolean;
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof KinvaultCoupon>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?: string | ((item: KinvaultCoupon) => string | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return GenericModelField({
    reactive,
    selectField,
    slots,
    props,
    simplifyData,
    model: KinvaultCoupon,
    optionTitleField:
      optionTitleField ??
      ((item: KinvaultCoupon) => {
        let discount = "";

        if (item.amountOff) {
          discount = ` ${new Currency({
            amount: item.amountOff / 100,
            type: item.currency as Currency["type"],
          }).toFormattedString()}`;
        }

        if (item.percentOff) {
          discount = ` (${item.percentOff}%)`;
        }
        return `${item.name}${discount}`;
      }),
    optionLine1Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      const { kinvaultCoupon } =
        await window.Kernel.ActionBus2.core.select.ReadKinvaultCoupon(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return kinvaultCoupon;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      }
      const { kinvaultCoupon: options } =
        await window.Kernel.ActionBus2.core.select.ReadKinvaultCoupon(
          {
            query: query ?? {},
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });
};
// KinvaultSubscriptionReferralCode
export const KinvaultSubscriptionReferralCodeField = <Data>({
  reactive,
  selectField,
  props,
  query,
  simplifyData = false,
  optionTitleField,
  optionLine1Field,
  slots,
  limit = 20,
}: {
  reactive?: boolean;
  selectField?: boolean;
  limit?: number;
  props: FormBuilderComponentOptions<
    typeof AutoCompleteField,
    Data
  >["props"] & {
    vModel:
      | string
      | ComponentModelType<typeof AutoCompleteField, Data>["value"];
  };
  query?: QueryMask<typeof KinvaultSubscriptionReferralCode>;
  simplifyData?: boolean;
  optionTitleField?: string | ((item: any) => string);
  optionLine1Field?:
    | string
    | ((item: KinvaultSubscriptionReferralCode) => string | undefined);
  slots?: FormBuilderComponentOptions<typeof AutoCompleteField, Data>["slots"];
}): ReturnType<
  typeof FormAutoCompleteField<Data> | typeof FormSelectField<Data>
> => {
  return GenericModelField({
    reactive,
    selectField,
    slots,
    props,
    simplifyData,
    model: KinvaultSubscriptionReferralCode,
    optionTitleField:
      optionTitleField ??
      ((item: KinvaultSubscriptionReferralCode) => {
        return `${item.code}`;
      }),
    optionLine1Field,
    asyncLoad: async (options) => {
      if (!options.map((v) => typeof v).onlyIncludes("string")) {
        return options;
      }

      if (options.length === 0) {
        return options;
      }
      const { kinvaultSubscriptionReferralCode } =
        await window.Kernel.ActionBus2.core.select.ReadKinvaultSubscriptionReferralCode(
          {
            query: {
              id: In(options),
            },
          },
          {
            hideLoading: true,
          },
        );

      return kinvaultSubscriptionReferralCode;
    },
    asyncFilter: async (search?: string | null) => {
      if (!query) {
        query = {};
      }

      if (![null, "", undefined].includes(search)) {
        Object.assign(query, {
          name: Like(search as string),
        });
      }
      const { kinvaultSubscriptionReferralCode: options } =
        await window.Kernel.ActionBus2.core.select.ReadKinvaultSubscriptionReferralCode(
          {
            query: query ?? {},
            pagination:
              null === limit || true === selectField
                ? false
                : {
                    currentPage: 0,
                    perPage: limit,
                  },
          },
          {
            hideLoading: true,
          },
        );

      return options;
    },
  });
};
