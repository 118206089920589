<template>
  <div class="knowledge-base-category-details">
    <KnowledgeBaseWrapper>
      <template #content>
        <MasterListPage
          v-if="$data.filters"
          :filters="$data.filters"
          :columns="columns"
          :rows="rows"
          v-model:current-page="pagination.currentPage"
          v-model:per-page="pagination.perPage"
          :count="pagination.count"
          :last-page="pagination.lastPage"
          @refresh="refresh"
          @row-clicked="handleClick"
          :title="knowledgeBaseTopLevelCategory?.title"
          subtitle="Category Master List"
          :sort-by-options="{
            createdAt: 'Created',
            title: 'Title',
          }"
          v-model:sort-by="sort.by"
          v-model:sort-direction="sort.direction"
        >
          <template #buttons>
            <Button
              v-if="hasWritePermission"
              class="create-category"
              size="is-normal"
              @click="create"
              tooltip="Create Category"
              icon-left="Plus"
              is-plain
            />
          </template>
        </MasterListPage>
      </template>
    </KnowledgeBaseWrapper>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KnowledgeBaseCategoryMasterListWrapper",
  "route": "KnowledgeBase",
  "selector": ".knowledge-base-category-details",
  "imports": {
    "ReadKnowledgeBaseCategoryFormWrapper": "@/module/knowledge-base/form/read-knowledge-base-category.form.test",
    "UpdateKnowledgeBaseCategoryFormWrapper": "@/module/knowledge-base/form/update-knowledge-base-category.form.test",
    "KnowledgeBaseCategoryDetailsWrapper": "@/module/knowledge-base/page/KnowledgeBaseCategoryDetails.test",
    "KnowledgeBaseWrapper": "@/module/knowledge-base/page/KnowledgeBaseWrapper.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "buttons": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KnowledgeBaseWrapper"
    },
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadKnowledgeBaseCategoryFormWrapper"
    },
    "createCategory": {
      "type": "click",
      "selector": ".create-category"
    },
    "categoryForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateKnowledgeBaseCategoryFormWrapper"
    },
    "categoryDetails": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KnowledgeBaseCategoryDetailsWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateKnowledgeBaseCategory } from "@/config/model.config";
import { ReadKnowledgeBaseCategoryForm } from "@/module/knowledge-base/form/read-knowledge-base-category.form";
import { UpdateKnowledgeBaseCategoryForm } from "@/module/knowledge-base/form/update-knowledge-base-category.form";
import { KnowledgeBaseCategoryMixin } from "@/module/knowledge-base/mixin/knowledge-base-category.mixin";
import KnowledgeBaseWrapper from "@/module/knowledge-base/page/KnowledgeBaseWrapper.vue";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, Equal, IsNotNull, Like } from "@kinherit/orm/index";
import { IKnowledgeBaseCategory, KnowledgeBaseCategory } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  KnowledgeBaseCategoryDetailsParams,
  KnowledgeBaseCategoryDetailsRoute,
  KnowledgeBaseRoute,
} from ".";

export default defineComponent({
  name: KnowledgeBaseRoute,
  components: { KnowledgeBaseWrapper, MasterListPage, Button },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.knowledgeBase),
    KnowledgeBaseCategoryMixin(),
  ],
  data: () => ({
    filters: ReadKnowledgeBaseCategoryForm(),
    columns: [
      {
        title: "Section",
        sort: true,
        map: (v: KnowledgeBaseCategory) => v.parentCategory?.title ?? "None",
      },
      {
        title: "Category",
        sort: true,
        map: (v: KnowledgeBaseCategory) => v.title,
      },
    ] as Array<TableColumn>,
    rows: Array<KnowledgeBaseCategory>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 25,
      count: 0,
    },
    sort: {
      by: "title" as keyof IKnowledgeBaseCategory,
      direction: "asc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasRole("admin");
    },
  },
  async mounted() {
    await this.refresh(this.filters.localData);
  },
  watch: {
    knowledgeBaseTopLevelCategory: {
      handler: "refresh",
      deep: true,
    },
  },
  methods: {
    handleClick(
      knowledgebasecategory: KnowledgeBaseCategory,
      event: MouseEvent,
    ): void {
      const params: KnowledgeBaseCategoryDetailsParams = {
        topLevelCategory: knowledgebasecategory.$data.parentCategory as string,
        category: knowledgebasecategory.id,
      };

      window.Kernel.visitRoute(
        {
          name: KnowledgeBaseCategoryDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadKnowledgeBaseCategoryForm>["localData"],
    ): Promise<void> {
      console.log(
        "refresh",
        formData,
        this.filters.localData,
        this.knowledgeBaseTopLevelCategory,
      );
      formData = formData ?? this.filters?.localData ?? {};

      const data = await this.$actionBus.ReadKnowledgeBaseCategory({
        query: [
          {
            title: Like(formData.name),
            createdAt: Between(formData.created),
            parentCategory: this.knowledgeBaseTopLevelCategory?.id
              ? Equal(this.knowledgeBaseTopLevelCategory.id)
              : IsNotNull(),
          },
          {
            documents: {
              title: Like(formData.name),
            },
            createdAt: Between(formData.created),
          },
          {
            documents: {
              content: Like(formData.name),
            },
            createdAt: Between(formData.created),
          },
        ],
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.knowledgeBaseCategory;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async create(): Promise<void> {
      if (!this.knowledgeBaseTopLevelCategory) {
        return;
      }

      const newCategory = CreateKnowledgeBaseCategory();
      newCategory.parentCategory = this.knowledgeBaseTopLevelCategory;
      const knowledgeBaseCategory = await UpdateKnowledgeBaseCategoryForm({
        category: newCategory,
        isTopLevel: false,
      }).dialog({
        dialog: {
          title: "Create Knowledge Base Category",
        },
      });

      knowledgeBaseCategory.$persist();

      // await window.Kernel.ActionBus.knowledgeBase.category.create({
      await this.$actionBus.CreateKnowledgeBaseCategory(knowledgeBaseCategory);

      this.refresh(this.filters?.localData ?? {});
    },
  },
});
</script>
