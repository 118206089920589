import { In } from "@kinherit/orm/index";
import { Option } from "@kinherit/sdk";

export type OptionGroup =
  | "kintinStatus"
  | "kintinTypes"
  | "process"
  | "kintinStage"
  | "officerCallStatus"
  | "subscriptionStatus"
  | "orderStatus"
  | "paymentType"
  | "leadStatus"
  | "introducerStatus"
  | "introducerType"
  | "introducerStage"
  | "introducerContactStatus"
  | "introducerContractType"
  | "introducerCpdType"
  | "trustRegistrationStatus"
  | "callDirections"
  | "appointmentType"
  | "emailTemplateType"
  | "appointmentOutcome"
  | "titles"
  | "classGiftTypes"
  | "businessAssetTypes"
  | "businessTypes"
  | "cashDebtTypes"
  | "giftTypes"
  | "investmentTypes"
  | "otherAssetTypes"
  | "pensionTypes"
  | "policyTypes"
  | "policyPayOutTypes"
  | "ownershipTypes"
  | "titles"
  | "howOwnedTypes"
  | "propertyTypes"
  | "ownedTypes"
  | "willReviewCategories"
  | "willWriterTypes"
  | "referralSources"
  | "introducerContractVat"
  | "orderDiscountReason"
  | "introducerCpdStatus"
  | "organisationTypes"
  | "clientPoolTypes"
  | "organisationSiteMode"
  | "fileLocations"
  | "filePolicyTypes"
  | "storageProviders"
  | "fileTypes"
  | "disclaimerClause"
  | "disclaimerCondition"
  | "ifPredeceasedTypes"
  | "kinvaultTheme"
  | "kinvaultThemeNavbar"
  | "kinvaultThemeFooter"
  | "kinvaultThemeFooterClass"
  | "kinvaultThemeHeader"
  | "kinvaultThemeHeaderLogoClass"
  | "kinvaultThemeFooterLogoClass"
  | "kinvaultThemeLogin"
  | "kinvaultThemeLoginScreenImage"
  | "teamRoles"
  | "kintinAccess"
  | "maritalStatuses"
  | "genders"
  | "qualifiedOut"
  | "introducerRemittanceType"
  | "agentRemittanceType"
  | "notificationStatus"
  | "signDocType"
  | "emailTemplateIncludeTo"
  | "preloadedEmailAttachments"
  | "physicalStorageFileLogActions"
  | "introducerCpdCallStatus"
  | "communicationNoteOutcome"
  | "relationRelationship"
  | "relationType"
  | "packingSlipProducts"
  | "userStatus"
  | "introducerOutsourceTypes"
  | "introducerOutsourceStatuses"
  | "kinvaultStatus"
  | "lifeTimeGiftTypes"
  | "introducerFeePaymentRunStatus"
  | "introducerFeePaymentStatus";

export class OptionService {
  private static groups: Array<OptionGroup> = [
    "kintinStatus",
    "kintinTypes",
    "process",
    "kintinStage",
    "officerCallStatus",
    "subscriptionStatus",
    "orderStatus",
    "paymentType",
    "leadStatus",
    "introducerStatus",
    "introducerType",
    "introducerStage",
    "introducerContactStatus",
    "introducerContractType",
    "introducerCpdType",
    "trustRegistrationStatus",
    "callDirections",
    "appointmentType",
    "emailTemplateType",
    "appointmentOutcome",
    "titles",
    "classGiftTypes",
    "businessAssetTypes",
    "businessTypes",
    "cashDebtTypes",
    "giftTypes",
    "investmentTypes",
    "otherAssetTypes",
    "pensionTypes",
    "policyTypes",
    "policyPayOutTypes",
    "ownershipTypes",
    "titles",
    "howOwnedTypes",
    "propertyTypes",
    "ownedTypes",
    "willReviewCategories",
    "willWriterTypes",
    "referralSources",
    "introducerContractVat",
    "orderDiscountReason",
    "introducerCpdStatus",
    "organisationTypes",
    "clientPoolTypes",
    "organisationSiteMode",
    "fileLocations",
    "filePolicyTypes",
    "storageProviders",
    "fileTypes",
    "disclaimerClause",
    "disclaimerCondition",
    "ifPredeceasedTypes",
    "kinvaultTheme",
    "kinvaultThemeNavbar",
    "kinvaultThemeFooter",
    "kinvaultThemeFooterClass",
    "kinvaultThemeHeader",
    "kinvaultThemeHeaderLogoClass",
    "kinvaultThemeFooterLogoClass",
    "kinvaultThemeLogin",
    "kinvaultThemeLoginScreenImage",
    "teamRoles",
    "kintinAccess",
    "maritalStatuses",
    "genders",
    "qualifiedOut",
    "introducerRemittanceType",
    "agentRemittanceType",
    "notificationStatus",
    "signDocType",
    "emailTemplateIncludeTo",
    "preloadedEmailAttachments",
    "physicalStorageFileLogActions",
    "introducerCpdCallStatus",
    "communicationNoteOutcome",
    "relationRelationship",
    "relationType",
    "packingSlipProducts",
    "userStatus",
    "introducerOutsourceTypes",
    "introducerOutsourceStatuses",
    "kinvaultStatus",
    "lifeTimeGiftTypes",
    "introducerFeePaymentRunStatus",
    "introducerFeePaymentStatus",
  ];

  public static async loadOptions(): Promise<void> {
    await Promise.all(
      OptionService.groups
        .chunk(Math.ceil(OptionService.groups.length / 2))
        .map((groups) =>
          // window.Kernel.ActionBus.core.option.read({
          window.Kernel.ActionBus2.core.options.Read({
            query: {
              group: In(groups),
            },
            sort: {
              by: "text",
              direction: "asc",
            },
            pagination: false,
          }),
        ),
    );
  }

  public static getOptions(group: OptionGroup, asArray: true): Option[];
  public static getOptions(
    group: OptionGroup,
    asArray: false,
  ): Record<string, string>;
  public static getOptions(
    group: OptionGroup,
    asArray = true,
  ): Option[] | Record<string, string> {
    if (asArray && OptionService.arrayOptions[group]) {
      return OptionService.arrayOptions[group] as Option[];
    }

    if (!asArray && OptionService.objectOptions[group]) {
      return OptionService.objectOptions[group] as Record<string, string>;
    }

    const options = Option.$findBy({
      group,
    }).sortBy("text");

    if (asArray) {
      OptionService.arrayOptions[group] = options;
      return options;
    }

    const optionsObject: Record<string, string> = {};

    options.forEach((option) => {
      optionsObject[option.id] = option.text;
    });

    OptionService.objectOptions[group] = optionsObject;
    return optionsObject;
  }

  private static arrayOptions: Partial<Record<OptionGroup, Option[]>> = {};
  private static objectOptions: Partial<
    Record<OptionGroup, Record<string, string>>
  > = {};
}
