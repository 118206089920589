// EmailTemplate

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { BrandedKinvaultCta } from "@kinherit/sdk/index";

export const CreateBrandedKinvaultCta = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/branded-kinvault-cta",
  method: "post",
  parse: (brandedKinvaultCta: BrandedKinvaultCta) => ({
    body: {
      position: brandedKinvaultCta.$data.position,
      template: brandedKinvaultCta.$data.template,
      categories: brandedKinvaultCta.$data.categories as any,
      internalName: brandedKinvaultCta.$data.internalName,
      introducerCompany: brandedKinvaultCta.$data.introducerCompany,
      referralCodes: brandedKinvaultCta.$data.referralCodes,
      title: brandedKinvaultCta.$data.title,
      displayFrom: brandedKinvaultCta.$data.displayFrom,
      displayTo: brandedKinvaultCta.$data.displayTo,
      content: brandedKinvaultCta.$data.content,
      conditions: brandedKinvaultCta.$data.conditions as any,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
