<template>
  <Card title="Cron Jobs">
    <div class="buttons">
      <Button @click="syncKinvaultCoupons">Sync Kinvault Coupons</Button>
      <Button @click="syncKinvaultPrices">Sync Kinvault Prices</Button>
      <Button @click="syncKinvaultSubscriptions"
        >Sync Kinvault Subscriptions</Button
      >
    </div>
  </Card>
</template>

<script lang="ts">
import { SettingsCronJobsRoute } from "@/module/settings/page";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";

export default defineComponent({
  name: SettingsCronJobsRoute,
  components: {
    Card,
    Button,
  },
  methods: {
    syncKinvaultCoupons() {
      window.Kernel.ActionBus2.external.cronJobs.SyncKinvaultCoupons({});
    },
    syncKinvaultPrices() {
      window.Kernel.ActionBus2.external.cronJobs.SyncKinvaultPrices({});
    },
    syncKinvaultSubscriptions() {
      window.Kernel.ActionBus2.external.cronJobs.SyncKinvaultSubscriptions({});
    },
  },
});
</script>
