<template>
  <div class="email-template-master-list">
    <PageHeader htag="h1" text="Email Templates">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          class="create"
          @click="createEmailTemplate"
        >
          Create Template
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      :sort-by-options="{
        createdAt: 'Created',
        name: 'Name',
        subject: 'Subject',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "EmailTemplateMasterListWrapper",
  "route": "EmailTemplateMasterList",
  "selector": ".email-template-master-list",
  "imports": {
    "ReadEmailTemplateFormWrapper": "@/module/admin.email-template/form/read-email-template.form.test",
    "EmailTemplateDetailsWrapper": "@/module/admin.email-template/page/details/EmailTemplateDetails.test",
    "UpdateEmailTemplateFormWrapper": "@/module/admin.email-template/form/update-email-template.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadEmailTemplateFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "EmailTemplateDetailsWrapper"
    },
    "createEmailTemplate": {
      "type": "click",
      "selector": ".create"
    },
    "createEmailTemplateForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "UpdateEmailTemplateFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { EmailTemplateTableColumns } from "@/config/table-columns.config";
import { ReadEmailTemplateForm } from "@/module/admin.email-template/form/read-email-template.form";
import { AuthService } from "@/service/auth.service";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, In, Like } from "@kinherit/orm/index";
import { EmailTemplate, IEmailTemplate } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { EmailTemplateMasterListRoute } from ".";
import { UpdateEmailTemplateForm } from "../form/update-email-template.form";
import {
  EmailTemplateDetailsParams,
  EmailTemplateDetailsRoute,
} from "./details";

export default defineComponent({
  name: EmailTemplateMasterListRoute,
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.admin.emailTemplates),
  ],
  components: { MasterListPage, PageHeader, Button },
  data: () => ({
    filters: ReadEmailTemplateForm(),
    columns: EmailTemplateTableColumns,
    rows: Array<EmailTemplate>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      // TODO add sorted by name ASC as default
      by: "name" as keyof IEmailTemplate,
      direction: "asc" as "desc" | "asc",
    },
  }),
  computed: {
    hasWritePermission(): boolean {
      return this.$auth.hasRole("admin");
    },
  },
  methods: {
    handleClick(emailtemplate: EmailTemplate, event: MouseEvent): void {
      const params: EmailTemplateDetailsParams = {
        emailTemplate: emailtemplate.id,
      };

      window.Kernel.visitRoute(
        {
          name: EmailTemplateDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadEmailTemplateForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.adminEmailTemplate.read({});
      const data = await this.$actionBus.ReadEmailTemplate({
        query: [
          {
            name: Like(formData.search),
            type: {
              id: In(formData.type),
            },
            createdAt: Between(formData.created),
          },
          {
            subject: Like(formData.search),
            type: {
              id: In(formData.type),
            },
            createdAt: Between(formData.created),
          },
          {
            body: Like(formData.search),
            type: {
              id: In(formData.type),
            },
            createdAt: Between(formData.created),
          },
        ],
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.emailTemplate;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async createEmailTemplate(): Promise<void> {
      const emailTemplate = await UpdateEmailTemplateForm().dialog({
        dialog: {
          title: "Create Template",
        },
      });

      // await window.Kernel.ActionBus.adminEmailTemplate.create({
      await this.$actionBus.CreateEmailTemplate(emailTemplate);

      this.refresh(this.filters.localData);
    },
  },
});
</script>
