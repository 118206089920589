import { Api } from "@/service/api.service";
import { Kintin } from "@kinherit/sdk";

type createOpgRoyalMailOrderMessage = {
  kintin: string | Kintin;
};

interface createOpgRoyalMailOrderResponse {
  [key: string]: never;
}

export const CreateOpgRoyalMailOrder = async (
  message: createOpgRoyalMailOrderMessage,
): Promise<createOpgRoyalMailOrderResponse> => {
  const kintin =
    "string" === typeof message.kintin ? message.kintin : message.kintin.id;

  await Api.resource(
    "portal-2",
    "/v2/portal-2/kinvault/{kintin}/process/create-opg-royal-mail-order",
  )
    .method("get")
    .params({
      kintin,
    })
    .result();

  return {};
};
