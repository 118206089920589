import { ActiveSession } from "@/module/auth/model/active-session.model";
import { Api } from "@/service/api.service";

interface CompleteMultiFactorAuthMessage {
  activeSession: ActiveSession;
  code: number;
  multiFactorSessionId: string;
}

interface CompleteMultiFactorAuthResult {
  activeSession: ActiveSession;
}

export const CompleteMultiFactorAuthHandler = async (
  message: CompleteMultiFactorAuthMessage,
): Promise<CompleteMultiFactorAuthResult> => {
  const session = message.activeSession;

  const response = await Api.resource("auth", "/v2/auth/multi-factor/complete")
    .method("put")
    .body({
      code: message.code,
      session: message.multiFactorSessionId,
    })
    .result();

  const activeSession = new ActiveSession({
    username: session.username,
    methods: [],
    mfaRequired: false,
    token: response.token,
    mfaHasMethods: session.mfaHasMethods,
    mfaSetupRequired: session.mfaSetupRequired,
    user: session.user,
  });

  activeSession.$persist();

  // await window.Kernel.ActionBus.execute("auth/session/complete", {
  //   activeSession,
  // });

  await window.Kernel.ActionBus2.auth.session.CompleteSession({
    activeSession,
  });

  return { activeSession };
};
