<template>
  <div class="lead-details-ifa-call-script-2025">
    <PageHeader text="Call Script (2025)" htag="h2" />
    <CallScriptSection title="Pre-call Notes">
      Tone: Professional and warm/attentive/engaged … not too friendly or
      familiar. <br /><br />
      <b class="is-block">Do’s:</b>
      - Be confident in approach … <br />- Take time to listen to them / be
      interested (eg in the names of their kids) <br /><br />
      <b class="is-block"> Don’ts: </b>
      - Apologise, or say “sorry” … we’re not delivering bad news
      <br />- Use the word “simple” … every client deserves careful attention /
      … is unique <br />- Never say “free” … if asked, say “you only pay when
      you decide what you want to do” as a much better way to give reassurance.
    </CallScriptSection>
    <CallScriptSection title="Initial Answer">
      <b>Hello is this {{ leadTitle }} {{ leadLastName }}?</b><br />
      <em>[Pause and wait for confirmation: avoid bland filler phrases]</em
      ><br />
      <br />
      <b>
        My name is {{ firstName }} and I’m one of the Legal Assistants at
        Kinherit, the will-writing firm.
      </b>
      <span v-if="introducerContactFullName || referralSource">
        <b>
          We were given your details by {{ introducerContactFullName }} at
          {{ referralSource }} who asked us to give you a call.
        </b>
        <br />
        <em>[Pause and wait for reply]</em>
      </span>
      <span v-else>
        <b
          >It looks like you’ve [completed the online form] / [ been introduced
          to us].
        </b>
      </span>
      <br />
      <br />
      <b>
        Do you have a few minutes now so we can book you in with one of our
        estate planners to discuss your will and/or estate planning? </b
      ><br />
      <em>[Short pause and wait for reply]</em><br />
      <em
        >[IF NO – take details / etc. <b>Be aware</b> if they’re just not up for
        a call… try to identify the block and deal with it in this call if
        possible….</em
      >
      <br />
      <br />
      <b>Great.</b>
      <br />
      <br />
      <b
        >First things first – can I confirm what is the best name for me to call
        you?</b
      >
      <br />
      <em
        >[Be aware it could be {{ leadFirstName }}, or {{ leadTitle }}
        {{ leadLastName }}<br />Check if a Tom or Thomas etc<br />Check
        pronounciation]</em
      >
      <br />
      <br />
      <b
        >Thank you for confirming, so just to remind you my name is
        {{ firstName }}</b
      >
    </CallScriptSection>
    <CallScriptSection title="About Us">
      <b v-if="introducerContactFirstName"
        >As mentioned, {{ introducerContactFirstName }} suggested we connect, so
        we can discuss your situation and hopefully set you up with a call with
        <span v-if="estatePlannerFirstName">{{ estatePlannerFirstName }}</span
        ><span v-else>one of our Estate Planners</span>.</b
      >
      <b v-else>
        Hopefully today we can discuss your situation and set you up with a call
        with
        <span v-if="estatePlannerFirstName">{{ estatePlannerFirstName }}</span
        ><span v-else>one of our Estate Planners</span>.
      </b>
      <br />
      <em>[Pause]</em>
      <br />
      <br />
      <b>
        Before I do, is there anything that makes your planning urgent and
        time-critical?
      </b>
      <br /><em
        >[Pause and wait for a reply, and **Add Note if Urgent** and say you’ll
        be sure to prioritise.]</em
      >
      <br />
      <br />
      <b>Thanks, I always want to check.</b>
      <br /><br />
      <b
        >So quickly on us: <br />
        <br />
        We are a firm where all advisers are qualified through STEP, which is
        the UK's leading governing body for will writing and estate planning. We
        are also an affiliate of the FCA's Consumer Duty Alliance
        <span v-if="introducerContactFirstName"
          >, which is why firms like {{ introducerContactFirstName }}'s refer to
          us</span
        >. </b
      ><em>[Short Pause]</em>
      <br />
      <br />

      <b
        >We provide an advised process, which means that you get advice that is
        specific to your personal situation.<span
          v-if="introducerContactFirstName"
        >
          So like you receive from {{ introducerContactFirstName }}</span
        >. </b
      ><em>[Short Pause]</em>
      <br />
      <br />
      <b
        >To protect you and your estate, all of our calls and discussions are
        recorded so we can defend your wishes if ever challenged.</b
      >
      <em>[Short Pause]</em>
      <br />
      <br />
      <b
        >Our clients also get a comprehensive estate handover service, which
        makes sure your key legal, financial and practical instructions get to
        the right people - your <em>trusted</em> people - when needed.</b
      >
      <br />
      <br />
      <b> Does this all make sense?</b>
      <br /><em>[Take response if forthcoming, else continue…]</em>
      <br />
      <br />
      <b>
        Everything is designed to protect your wishes, and give your family an
        easier process in the event of death or incapacitation.</b
      >
      <br />
      <em>[Pause]</em>
    </CallScriptSection>
    <CallScriptSection title="Book Call">
      <b>
        As discussed, I'd like to get you booked in for a call with
        <span v-if="introducerContactFullName"
          >{{ estatePlannerFullName }} who covers
          {{ introducerContactFullName }} and his clients.
          <em>[But if urgent, check next available]</em></span
        ><span v-else> one of our estate planners.</span>
      </b>
      <br />
      <br />

      <b
        >I can see [one slot on][+1-2 days out] and another slot on [+2-4 days
        out]. Could either of those work?</b
      >
      <br /><em>[Pause and wait for reply]</em> <br /><br />
      <b>Our preferred method of meeting is Zoom - does that work for you?</b>
      <br /><em>[Pause and wait for reply]</em> <br /><br />
      <b
        >I’ll send a confirmation of all this to your email... can I
        double-check the address I have is right: {{ email }}</b
      >
      <br /><em>[Pause and wait for reply]</em> <br /><br />

      <b>OK thanks, that is now in their diary.</b>
      <br /><br />
      <b
        >We do ask that if you can’t make your session, to please let us know.
        Ideally at least a couple of days’ so we can re-assign the slot to
        someone else.</b
      >
    </CallScriptSection>

    <CallScriptSection title="Follow on Questions">
      <b
        >To help {{ estatePlannerFirstName }} be prepared for your call, can I
        ask a couple of questions:</b
      >
      <br />
      <b>- Is the planning for you alone, or you and a partner? </b>
      <br />
      - <em>[IF JOINT]</em> <b>And can I take your partner’s name?</b
      ><em>[Add note, repeat the name.]</em> <br />
      - <em>[If unclear - check marital status]</em> <br />
      <b>- Is there something specific you are after, like a Will or LPA?</b
      ><em>[Add note]</em>
      <b v-if="!introducerContactFullName"
        ><br />- [IF website lead - can I double-check how you heard about
        Kinherit?]</b
      >
      <br />
      <b
        >- Are you a homeowner? <br />
        - Do you own any other properties? <br />
        - Are you a business owner or own any business assets such as shares?
        <br />
        - Do you own any Agricultural assets? <br />
        - Lastly, Do you have children? And are any of these under the age of
        18?
      </b>
      <em>[can I get their names?]</em><br />
      <em>[If yes get note, repeat the all the names.]</em>
      <br /><br />
      <b>
        That’s everything {{ estatePlannerFirstName }} needs at this stage, and
        just to make sure, I'll recap: [Summarise Family / Kids].
      </b>
      <br /><br />
      <b>
        [He/she] helps many people in your situation every year, so I’m sure
        will be able to help.
      </b>
    </CallScriptSection>
    <CallScriptSection title="Set Expectations">
      <b
        >After this call, I’ll send you our Letter of Engagement. It’ll say what
        you can expect from us, how we keep your information confidential, and
        includes some example pricing. Please do read it and let me know if it
        raises anything.
        <br />
        <br />Your call with {{ estatePlannerFirstName }} will cover your
        situation and what you want to achieve in detail. It is very thorough -
        and we help you at every step.
        <br />
        <br />I am also proactive in chasing - so please forgive me in advance!
        - it’s to get you protected as soon as possible.
        <br />
        <br />As mentioned, once your legal documents are signed we’ll move onto
        the handover planning stage, but {{ estatePlannerFirstName }} will cover
        this in more detail.
        <br />
        <br />As a reminder my name is {{ fullName }} and I’m one of the Legal
        Assistants here.
        <br />
        <br />I’m sure we’ll be speaking again through the process, but
        meanwhile do you have any final questions?
      </b>
      <br /><em>[Pause and wait for a reply]</em>
      <br />
      <br />
      <b
        >Fantastic – you should get a confirmation email shortly – [it includes
        the Zoom link to follow at the time of your appointment]. And please do
        let us know if something comes up.
        <br />
        <br />So to confirm, [Summarise planning/family] and
        {{ estatePlannerFirstName }} will speak to you on [Date/time].
        <br />
        <br />Thank you</b
      >
    </CallScriptSection>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "LeadDetailsIfaCallScriptWrapper",
  "route": "LeadDetailsIfaCallScript",
  "selector": ".lead-details-ifa-call-script"
}
</cypress-wrapper>

<script lang="ts">
import { AuthService } from "@/service/auth.service";
import { StyleService } from "@/service/style.service";
import PageHeader from "@kinherit/framework/component.layout/page-header";
import { IntroducerContact, Profile } from "@kinherit/sdk/index";
import { defineComponent } from "vue";
import { LeadDetailsIfaCallScript2025Route } from ".";
import { LeadDetailsMixin } from "../../../mixin/lead-details.mixin";

import CallScriptSection from "@/module/kinvault.kintin/component/call-script/shared/Section.vue";

export default defineComponent({
  name: LeadDetailsIfaCallScript2025Route,
  mixins: [LeadDetailsMixin, StyleService.mixin, AuthService.mixin()],
  components: { PageHeader, CallScriptSection },
  computed: {
    firstName(): string {
      return this.$auth.loggedInUser?.profile.firstName || "[FIRST NAME]";
    },
    fullName(): string {
      return this.$auth.loggedInUser?.profile.fullName || "[Full NAME]";
    },
    leadTitle(): string {
      return this.lead?.profile.title?.text || "[Mr/Mrs/Ms]";
    },
    leadFirstName(): string {
      return this.lead?.profile.firstName || "[FIRST NAME]";
    },
    leadLastName(): string {
      return this.lead?.profile.lastName || "[LAST NAME]";
    },
    leadFullName(): string {
      return this.lead?.profile.fullName || "[Full NAME]";
    },
    email(): string {
      return this.lead?.profile.emails.first()?.email || "[EMAIL]";
    },
    estatePlannerProfile(): Profile | null {
      return this.lead?.ownedBy?.profile || null;
    },
    estatePlannerFirstName(): string {
      return this.estatePlannerProfile?.firstName || "[EP FIRST NAME]";
    },
    estatePlannerFullName(): string {
      return this.estatePlannerProfile?.fullName || "[EP FULL NAME]";
    },
    introducerContact(): IntroducerContact | null {
      return this.lead?.referral?.contact || null;
    },
    introducerContactFirstName(): string {
      return (
        this.introducerContact?.profile.firstName || "[INTRODUCER FIRST NAME]"
      );
    },
    introducerContactFullName(): string {
      return (
        this.introducerContact?.profile.fullName || "[INTRODUCER FULL NAME]"
      );
    },
    referralSource(): string {
      return this.lead?.referral?.referralCode?.name ?? "[REFERRAL SOURCE]";
    },
  },
});
</script>
