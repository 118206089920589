import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultPrice } from "@kinherit/sdk/index";

export const RecordKinvaultPrice = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/prices/{kinvaultPrice}",
  method: "get",
  parse: (message: string | KinvaultPrice) => ({
    params: {
      kinvaultPrice: message,
    },
  }),
  rootModel: KinvaultPrice,
});
