import { RouteInterface } from "@kinherit/framework/core/route-config";

export const KinvaultCouponMasterListRoute = "KinvaultCouponMasterList";
export const KinvaultPriceMasterListRoute = "KinvaultPriceMasterList";
export const KinvaultSubscriptionMasterListRoute =
  "KinvaultSubscriptionMasterList";
export const KinvaultSubscriptionReferralCodeMasterListRoute =
  "KinvaultSubscriptionReferralCodeMasterList";

export const Routes: Array<RouteInterface> = [
  {
    name: KinvaultCouponMasterListRoute,
    path: "/kinvaults/subscriptions/coupons",
    component: () => import("./KinvaultCouponMasterList.vue"),
    meta: {
      breadcrumbs: () => [
        {
          text: "Kinvault Subscriptions",
          route: {
            name: KinvaultSubscriptionMasterListRoute,
          },
        },
        {
          text: "Coupons",
          route: {
            name: KinvaultCouponMasterListRoute,
          },
        },
      ],
    },
  },
  {
    name: KinvaultPriceMasterListRoute,
    path: "/kinvaults/subscriptions//prices",
    component: () => import("./KinvaultPriceMasterList.vue"),
    meta: {
      breadcrumbs: () => [
        {
          text: "Kinvault Subscriptions",
          route: {
            name: KinvaultSubscriptionMasterListRoute,
          },
        },
        {
          text: "Prices",
          route: {
            name: KinvaultPriceMasterListRoute,
          },
        },
      ],
    },
  },
  {
    name: KinvaultSubscriptionMasterListRoute,
    path: "/kinvaults/subscriptions",
    component: () => import("./KinvaultSubscriptionMasterList.vue"),
    meta: {
      breadcrumbs: () => [
        {
          text: "Kinvault Subscriptions",
          route: {
            name: KinvaultSubscriptionMasterListRoute,
          },
        },
      ],
    },
  },
  {
    name: KinvaultSubscriptionReferralCodeMasterListRoute,
    path: "/kinvaults/subscriptions/referral-codes",
    component: () => import("./KinvaultSubscriptionReferralCodeMasterList.vue"),
    meta: {
      breadcrumbs: () => [
        {
          text: "Kinvault Subscriptions",
          route: {
            name: KinvaultSubscriptionMasterListRoute,
          },
        },
        {
          text: "Referral Codes",
          route: {
            name: KinvaultSubscriptionReferralCodeMasterListRoute,
          },
        },
      ],
    },
  },
];
