import {
  AccountReferralCodeField,
  IntroducerCompanyField,
} from "@/config/form.config";
import { FormAutoCompleteField } from "@kinherit/framework/component.input/auto-complete-field";
import { FormDateField } from "@kinherit/framework/component.input/date-field";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { BrandedKinvaultCta } from "@kinherit/sdk/index";

export const UpdateBrandedKinvaultCtaForm = (
  brandedKinvaultCta: BrandedKinvaultCta,
) =>
  defineForm({
    name: "update-branded-kinvault-cta",
    data: () => brandedKinvaultCta,
    formAreas: (data) => [
      defineFormArea({
        name: "default",
        data,
        template: GridLayout([
          ["internalName", "title"],
          ["displayFrom", "displayTo"],
          ["introducerCompany", "referralCodes"],
          ["position", "template"],
          ["categories"],
        ]),
        components: () => ({
          introducerCompany: [
            IntroducerCompanyField({
              props: {
                label: "Introducer Company",
                vModel: "introducerCompany",
              },
            }),
          ],
          title: [
            FormTextField({
              props: {
                label: "Title",
              },
              models: {
                value: "title",
              },
            }),
          ],
          displayFrom: [
            FormDateField({
              props: {
                label: "Display From",
              },
              models: {
                value: "displayFrom",
              },
            }),
          ],
          displayTo: [
            FormDateField({
              props: {
                label: "Display To",
              },
              models: {
                value: "displayTo",
              },
            }),
          ],
          referralCodes: [
            AccountReferralCodeField({
              props: {
                label: "Referral Codes",
                isMultiSelect: true,
                vModel: "referralCodes",
              },
            }),
          ],
          internalName: [
            FormTextField({
              props: {
                label: "Internal Name",
              },
              models: {
                value: "internalName",
              },
            }),
          ],
          position: [
            FormSelectField({
              props: {
                label: "Position",
                options: {
                  top: "Top",
                  bottom: "Bottom",
                },
              },
              models: {
                value: "position",
              },
            }),
          ],
          template: [
            FormSelectField({
              props: {
                label: "Template",
                options: {
                  default: "Default",
                },
              },
              models: {
                value: "template",
              },
            }),
          ],
          categories: [
            FormAutoCompleteField({
              props: {
                label: "Categories",
                isMultiSelect: true,
                options: {
                  "assets-accounts": "Assets - Accounts",
                  other: "Other",
                  "after-death-personal": "After Death - Personal",
                  "after-death-funeral": "After Death - Funeral",
                  contacts: "Contacts",
                  "care-plan": "Care Plan",
                  "admin-other": "Admin - Other",
                  "admin-work": "Admin - Work",
                  "admin-pets": "Admin - Pets",
                  "admin-contractors": "Admin - Contractors",
                  "admin-vehicles": "Admin - Vehicles",
                  "admin-insurance": "Admin - Insurance",
                  "admin-utilities": "Admin - Utilities",
                  "legal-after": "Legal - After",
                  "legal-before": "Legal - Before",
                  "assets-other": "Assets - Other",
                  "after-death-other": "After Death - Other",
                  "legal-other": "Legal - Other",
                  physicalstorage: "Physical Storage",
                  "contacts-uploads": "Contacts - Uploads",
                  "contacts-other-people": "Contacts - Other People",
                  "contacts-trusted-people": "Contacts - Trusted People",
                  "contacts-companies": "Contacts - Companies",
                  "contacts-charities": "Contacts - Charities",
                  "contacts-account-holders": "Contacts - Account Holders",
                  "assets-valuables": "Assets - Valuables",
                  "assets-uploads": "Assets - Uploads",
                  "assets-reports": "Assets - Reports",
                  "assets-properties": "Assets - Properties",
                  "assets-policies": "Assets - Policies",
                  "assets-pensions": "Assets - Pensions",
                  "assets-lifetime-gifts": "Assets - Lifetime Gifts",
                  "assets-investments": "Assets - Investments",
                  "assets-debts": "Assets - Debts",
                  "assets-business-assets": "Assets - Business Assets",
                },
              },
              models: {
                value: "categories",
              },
            }),
          ],
        }),
      }),
      // defineFormArea({
      //   name: "condition",
      //   data,
      //   template: GridLayout([["title"], ["default"]]),
      //   components: (data) => ({
      //     title: [
      //       FormTitle({
      //         props: {
      //           text: "Conditions",
      //           style: {
      //             display: "inline-block",
      //           },
      //         },
      //       }),
      //       FormButton({
      //         props: {
      //           iconLeft: "Plus",
      //           ariaLabel: "Add a condition",
      //           style: {
      //             float: "right",
      //           },
      //         },
      //         emits: {
      //           click: (_, data, controls) => {
      //             data.conditions = [
      //               ...data.conditions,
      //               {
      //                 type: "moreThan",
      //                 count: 1,
      //                 target: "contractors",
      //               },
      //             ];
      //             controls.rebuildForm();
      //           },
      //         },
      //       }),
      //     ],
      //     default: [
      //       FormElement({
      //         slots: {
      //           default: data.conditions.map((condition, index) =>
      //             FormElement({
      //               slots: {
      //                 default: [
      //                   FormSelectField({
      //                     props: {
      //                       label: "Type",
      //                       options: {
      //                         moreThan: "More Than",
      //                         lessThan: "Less Than",
      //                         equals: "Exactly",
      //                       },
      //                     },
      //                     models: {
      //                       value: {
      //                         get: () => data.conditions[index].type,
      //                         set: (value) => {
      //                           const conditions = data.conditions;
      //                           conditions[index].type = value;
      //                           data.conditions = conditions;
      //                         },
      //                       },
      //                     },
      //                   }),
      //                   FormNumberField({
      //                     props: {
      //                       label: "Count",
      //                     },
      //                     models: {
      //                       value: {
      //                         get: () => data.conditions[index].count,
      //                         set: (value) => {
      //                           const conditions = data.conditions;
      //                           conditions[index].count = value;
      //                           data.conditions = conditions;
      //                         },
      //                       },
      //                     },
      //                   }),
      //                   FormSelectField({
      //                     props: {
      //                       label: "Target",
      //                       options: {
      //                         contractors: "Contractors",
      //                         insurance: "Insurance",
      //                         other: "Other",
      //                         pets: "Pets",
      //                         utilities: "Utilities",
      //                         vehicles: "Vehicles",
      //                         work: "Work",
      //                         accounts: "Accounts",
      //                         "business-assets": "Business Assets",
      //                         debts: "Debts",
      //                         investments: "Investments",
      //                         pensions: "Pensions",
      //                         policies: "Policies",
      //                         properties: "Properties",
      //                         valuables: "Valuables",
      //                         "account-holders": "Account Holders",
      //                         charities: "Charities",
      //                         companies: "Companies",
      //                         "other-people": "Other People",
      //                         "trusted-people": "Trusted People",
      //                         "funerial-wishes": "Funerial Wishes",
      //                         "other-requests": "Other Requests",
      //                         "personal-notes": "Personal Notes",
      //                       },
      //                     },
      //                     models: {
      //                       value: {
      //                         get: () => data.conditions[index].target,
      //                         set: (value) => {
      //                           const conditions = data.conditions;
      //                           conditions[index].target = value;
      //                           data.conditions = conditions;
      //                         },
      //                       },
      //                     },
      //                   }),
      //                   FormButton({
      //                     props: {
      //                       iconLeft: "Trash",
      //                       ariaLabel: "Delete this condition",
      //                       style: {
      //                         float: "right",
      //                       },
      //                     },
      //                     emits: {
      //                       click: (_, data, controls) => {
      //                         const conditions = data.conditions;
      //                         conditions.splice(index, 1);
      //                         data.conditions = conditions;
      //                         controls.rebuildForm();
      //                       },
      //                     },
      //                   }),
      //                 ],
      //               },
      //             }),
      //           ),
      //         },
      //       }),
      //     ],
      //   }),
      // }),
      // defineFormArea({
      //   name: "texts",
      //   data,
      //   template: GridLayout([["title"], ["default"]]),
      //   components: (data) => ({
      //     title: [
      //       FormTitle({
      //         props: {
      //           text: "Texts",
      //           style: {
      //             display: "inline-block",
      //           },
      //         },
      //       }),
      //       FormButton({
      //         props: {
      //           iconLeft: "Plus",
      //           ariaLabel: "Add a text",
      //           style: {
      //             float: "right",
      //           },
      //         },
      //         emits: {
      //           click: (_, data, controls) => {
      //             const content = data.content;
      //             content.texts.push({
      //               text: "Text",
      //               fontSize: 6,
      //             });
      //             data.content = content;
      //             controls.rebuildForm();
      //           },
      //         },
      //       }),
      //     ],
      //     default: data.content.texts.map((text, index) =>
      //       FormElement({
      //         slots: {
      //           default: [
      //             FormSelectField({
      //               props: {
      //                 label: "Font Size",
      //                 options: {
      //                   1: "1",
      //                   2: "2",
      //                   3: "3",
      //                   4: "4",
      //                   5: "5",
      //                   6: "6",
      //                 } satisfies Record<
      //                   BrandedKinvaultCtaDefaultContent["texts"][number]["fontSize"],
      //                   string
      //                 >,
      //               },
      //               models: {
      //                 value: {
      //                   get: () =>
      //                     data.content.texts[index].fontSize.toString(),
      //                   set: (value) => {
      //                     const content = data.content;
      //                     content.texts[index].fontSize = parseInt(
      //                       value,
      //                     ) as BrandedKinvaultCtaDefaultContent["texts"][number]["fontSize"];
      //                     data.content = content;
      //                   },
      //                 },
      //               },
      //             }),
      //             FormTextField({
      //               props: {
      //                 label: "Text",
      //                 isTextArea: true,
      //               },
      //               models: {
      //                 value: {
      //                   get: () => data.content.texts[index].text,
      //                   set: (value) => {
      //                     const content = data.content;
      //                     content.texts[index].text = value;
      //                     data.content = content;
      //                   },
      //                 },
      //               },
      //             }),
      //             FormButton({
      //               props: {
      //                 iconLeft: "Trash",
      //                 ariaLabel: "Delete this text",
      //                 style: {
      //                   float: "right",
      //                 },
      //               },
      //               emits: {
      //                 click: (_, data, controls) => {
      //                   const content = data.content;
      //                   content.texts.splice(index, 1);
      //                   data.content = content;
      //                   controls.rebuildForm();
      //                 },
      //               },
      //             }),
      //           ],
      //         },
      //       }),
      //     ),
      //   }),
      // }),
    ],
  });
