<template>
  <MasterListPage
    class="branded-kinvault-details-call-to-actions"
    :columns="columns"
    :rows="rows"
    is-nested
    :filters="filters"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    @refresh="refresh"
    title="Call To Actions"
    @row-clicked="update"
    :sort-by-options="{
      createdAt: 'Created',
    }"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #buttons>
      <Button @click="create" color="is-primary">Create</Button>
    </template>
    <template #name="{ row }: { row: BrandedKinvaultCta }">
      <div>{{ row.internalName }}</div>
      <div class="has-text-grey" v-if="row.introducerCompany">
        {{ row.introducerCompany.profile.organisationName }}
      </div>
    </template>
    <template #actions="{ row }: { row: BrandedKinvaultCta }">
      <Button
        @click="remove(row)"
        color="is-danger"
        icon-left="Trash"
        aria-label="Delete"
      />
    </template>
  </MasterListPage>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsCallToActionsWrapper",
  "route": "BrandedKinvaultDetailsCallToActions",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "selector": ".branded-kinvault-details-calltoactions"
}
</cypress-wrapper>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { ReadBrandedKinvaultCtaForm } from "@/module/admin.branded-kinvault-cta/form/read-branded-kinvault-cta.form";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, In, Like } from "@kinherit/orm/index";
import { BrandedKinvaultCta, IBrandedKinvaultCta } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  AdminBrandedKinvaultCtaCreateRoute,
  AdminBrandedKinvaultCtaUpdateRoute,
  AdminBrandedKinvaultCtaUpdateRouteParams,
  BrandedKinvaultCtaMasterListRoute,
} from ".";

export default defineComponent({
  name: BrandedKinvaultCtaMasterListRoute,
  components: { MasterListPage, Button },
  mixins: [
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal2.admin.brandedKinvaults.cta,
    ),
  ],
  data: () => ({
    filters: ReadBrandedKinvaultCtaForm(),
    columns: [
      {
        title: "Internal Name",
        slot: "name",
      },
      {
        title: "Position",
        map: (cta) => cta.position.ucFirst(),
      },
      {
        title: "Template",
        map: (cta) => cta.template.ucFirst(),
      },
      {
        title: "Categories",
        // map: (cta) => cta.categories.join(", "),
        mapHtml: (cta) => cta.categories.join("<br>"),
      },
      {
        title: "Created",
        map: (cta) => cta.createdAt.formatYMD,
      },
      {
        blockClicks: true,
        slot: "actions",
      },
    ] as TableColumn<BrandedKinvaultCta>[],
    rows: Array<BrandedKinvaultCta>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IBrandedKinvaultCta,
      direction: "desc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      const { brandedKinvaultCta, $pagination } =
        await this.$actionBus.ReadBrandedKinvaultCta({
          query: {
            internalName: Like(this.filters.localData.name),
            introducerCompany: {
              id: In(this.filters.localData.introducerCompany.pluck("id")),
            },
            createdAt: Between(this.filters.localData.created),
          },
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = brandedKinvaultCta;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
    async update(cta: BrandedKinvaultCta): Promise<void> {
      console.log(cta);
      this.$router.push({
        name: AdminBrandedKinvaultCtaUpdateRoute,
        params: {
          brandedKinvaultCta: cta.id,
        } as AdminBrandedKinvaultCtaUpdateRouteParams,
      });
    },
    async create(): Promise<void> {
      this.$router.push({
        name: AdminBrandedKinvaultCtaCreateRoute,
      });
    },
    async remove(cta: BrandedKinvaultCta): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete CTA",
          message: "Are you sure you want to delete this call to action?",
        },
      });

      await this.$actionBus.DeleteBrandedKinvaultCta(cta);
      await this.refresh();
    },
  },
});
</script>
