import { Api } from "@/service/api.service";
import { calculateStartEndDates } from "@/module/report/data/helper-functions";
interface ComputeCompaniesLeaderboardReportMessage {
  referrerCategory: string | null;
  year: string;
  created: [number, number] | null;
  orderBy:
    | "referralCount"
    | "totalOrderValue"
    | "averageOrderValue"
    | "leadInitialConversionRate"
    | "accountDropoutRate"
    | "totalAccounts";
  direction: "asc" | "desc";
}

interface ComputeCompaniesLeaderboardTotals {
  averageConversionRate: number;
  averageDropoutRate: number;
  totalOrderValue: number;
  totalOrderCount: number;
  averageOrderValue: number;
  totalAccounts: number;
  totalReferrals: number;
}

interface ComputeCompaniesReportLeaderboardResponse {
  datasets: Array<TCompanyLeaderboardReportResult>;
  totals: ComputeCompaniesLeaderboardTotals;
}

export type TCompanyLeaderboardReportResult = {
  referralCodeId: string;
  referralCode: string;
  referralCodeName: string;
  referralCategory: string;
  totalReferrals: number;
  accountDropoutRate: number;
  totalOrderCount: number;
  totalOrderValue: number;
};

export const ComputeCompaniesLeaderboardReportHandler = async (
  message: ComputeCompaniesLeaderboardReportMessage,
): Promise<ComputeCompaniesReportLeaderboardResponse> => {
  const { referrerCategory, year, created } = message || {};

  const calculatedDates = calculateStartEndDates(year, created, null);
  const response = await Api.resource(
    "portal-2",
    "/v2/portal-2/report/companies/leaderboard",
    {},
  )
    .method("get")
    .params({
      referrerCategory: referrerCategory ?? null,
      startDate: calculatedDates.startDate,
      endDate: calculatedDates.endDate,
      orderBy: message.orderBy,
      direction: message.direction,
    })

    .result();

  return {
    datasets: response.datasets,
    totals: response.totals,
  };
};
