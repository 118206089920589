<template>
  <Card v-if="knowledgeBaseDocument" title="Files">
    <template #buttons>
      <Button
        v-if="$auth.hasRole('admin')"
        color="is-plain"
        icon-left="Plus"
        @click="uploadFile"
        >Upload</Button
      >
    </template>
    <KnowledgeBaseFileLogTable
      :key="renderKey"
      :rows="$data.rows"
      :allow-download="true"
      :allow-update="true"
      :allow-delete="true"
      @updateFile="updateFile"
      @downloadFile="downloadFile"
      @deleteFile="deleteFile"
    />
  </Card>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import Card from "@kinherit/framework/component.layout/card";
import { OpenTextDialog } from "@kinherit/framework/global/dialog";
import { FileLog } from "@kinherit/sdk/index";
import { defineComponent } from "vue";
import { KnowledgeBaseDocumentDetailsContentRoute } from ".";

import KnowledgeBaseFileLogTable from "@/module/knowledge-base/component/KnowledgeBaseFileLogTable.vue";
import { CreateKnowledgeBaseFileLogForm } from "../../form/create-knowledge-base-document-file-log.form";
import { KnowledgeBaseDocumentMixin } from "../../mixin/knowledge-base-document.mixin";
export default defineComponent({
  name: KnowledgeBaseDocumentDetailsContentRoute,
  components: { Card, Button, KnowledgeBaseFileLogTable },
  mixins: [AuthService.mixin(), KnowledgeBaseDocumentMixin()],
  data: () => ({
    columns: [
      {
        title: "File Name",
        map: (row: FileLog) => row.fileName,
      },
      {
        title: "Created",
        map: (row: FileLog) => row.createdAt.formatYMD,
      },
      {
        slot: "actions",
      },
    ] as TableColumn<FileLog>[],
    rows: [] as FileLog[],
    renderKey: 0,
  }),
  mounted() {
    this.rows = this.knowledgeBaseDocument?.fileLog ?? [];
    this.renderKey++;
  },
  methods: {
    async refresh(): Promise<void> {
      if (!this.knowledgeBaseDocument) {
        return;
      }

      // await window.Kernel.ActionBus.knowledgeBase.document.record({
      await this.$actionBus.RecordKnowledgeBaseDocument({
        message: this.knowledgeBaseDocument,
      });

      this.rows = this.knowledgeBaseDocument.fileLog;
      this.renderKey++;
    },
    async uploadFile(): Promise<void> {
      const { files } = await CreateKnowledgeBaseFileLogForm().dialog({
        dialog: {
          title: "Upload File",
        },
      });

      const [file] = files;

      if (!this.knowledgeBaseDocument || !file) {
        return;
      }

      // await window.Kernel.ActionBus.knowledgeBase.document.fileLog.create({
      await this.$actionBus.fileLog.CreateFileLog({
        file: file,
        knowledgeBaseDocument: this.knowledgeBaseDocument,
      });

      await this.refresh();
    },
    async deleteFile(file: FileLog): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete File",
          message: `Are you sure you want to delete "${file.fileName}"?`,
        },
      });

      if (!this.knowledgeBaseDocument) {
        return;
      }

      // await window.Kernel.ActionBus.knowledgeBase.document.fileLog.delete({
      await this.$actionBus.fileLog.DeleteFileLog({
        fileLog: file,
        knowledgeBaseDocument: this.knowledgeBaseDocument,
      });

      await this.refresh();
    },
    async updateFile(file: FileLog): Promise<void> {
      console.log("updateFile1", file);
      const fileName = await OpenTextDialog({
        dialog: {
          title: "Update File",
          message: "Enter the new file name",
        },
        value: file.fileName,
        input: {},
      });

      if (!this.knowledgeBaseDocument) {
        return;
      }

      file.fileName = fileName;
      file.$persist();

      console.log("updateFile2", file);

      // await window.Kernel.ActionBus.knowledgeBase.document.fileLog.update({
      await this.$actionBus.fileLog.UpdateFileLog({
        fileLog: file,
        knowledgeBaseDocument: this.knowledgeBaseDocument,
      });

      await this.refresh();
    },
    async downloadFile(file: FileLog): Promise<void> {
      if (!this.knowledgeBaseDocument) {
        return;
      }

      // await window.Kernel.ActionBus.knowledgeBase.document.fileLog.download({
      await this.$actionBus.fileLog.DownloadFileLog({
        file: file,
        knowledgeBaseDocument: this.knowledgeBaseDocument,
      });
    },
  },
});
</script>
