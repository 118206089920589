import { Api } from "@kinherit/sdk/api";
import { Kintin } from "@kinherit/sdk/index";

// AccountReferral, KintinPermissions, CallScript, Kintin, Person, BusinessAsset, LifeTimeGift, OtherAsset, Pension, Subscription, PhysicalStorageFile, PhysicalStorageFileLog, Policy, Property, CashDebt, Debt, Exclusion, Gift, Investment, UploadForm, WillReview, WillRevision, Note, KintinCheck, KintinCheckFollowUp, KintinCheckNote, KinvaultCheck, BrandedKinvaultReminder, EmailLog, FileLog, SignDoc, StatusLog, Adviser, Attorney, Beneficiary, Executor, Guardian, Trustee, Address, EmailAddress, PhoneNumber, Website, EmailNamedAttachment
export const DuplicateKintin = async (kintin: Kintin) => {
  return await Api.resource("portal-2", "/v2/portal-2/kinvault/duplicate")
    .method("put")
    .body({
      kintin: kintin.id,
    })
    .result();
};
