import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultSubscription } from "@kinherit/sdk/index";

export const RecordKinvaultSubscription = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/subscriptions/{kinvaultSubscription}",
  method: "get",
  parse: (message: string | KinvaultSubscription) => ({
    params: {
      kinvaultSubscription: message,
    },
  }),
  rootModel: KinvaultSubscription,
});
