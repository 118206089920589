import { Portal2AdminBrandedKinvaultCta } from "@/action-bus/portal-2/admin/branded-kinvaults/cta";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateBrandedKinvault } from "./create-branded-kinvault.action";
import { ReadBrandedKinvault } from "./read-branded-kinvault.action";

export const Portal2AdminBrandedKinvaults = DefineActionBus({
  name: "portal-2-admin-branded-kinvaults",
  actions: {
    CreateBrandedKinvault,
    ReadBrandedKinvault,
    cta: Portal2AdminBrandedKinvaultCta,
  },
});
