import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { KinvaultSubscription } from "@kinherit/sdk/index";

export const ReadKinvaultSubscription = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/subscriptions",
  method: "get",
  parse: () => ({}),
  rootModel: KinvaultSubscription,
});
