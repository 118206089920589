<template>
  <div class="kintin-details-address-book-addresses">
    <PageHeader htag="h2" text="Addresses" v-if="kintin" />

    <AddressList
      v-if="kintin"
      :kintin="kintin"
      :addresses="$data.addresses"
      @reload="filter"
    />
    <Json v-if="isDevMode" :data="addresses" />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "AddressesContainerWrapper",
  "selector": ".kintin-details-address-book-addresses",
  "imports": {
    "AddressListWrapper": "@/module/kinvault.kintin/component/lists/AddressList.test"
  },
  "methods": {
    "addressList": {
      "type": "to-one",
      "selector": ".address-list",
      "wrapper": "AddressListWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import AddressList from "@/module/kinvault.kintin/component/lists/AddressList.vue";
import { Json } from "@kinherit/framework/component.display/json";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { KernelModes } from "@kinherit/framework/core/kernel-mode";
import { In } from "@kinherit/orm";
import { Address, Kintin, Person } from "@kinherit/sdk";
import { defineComponent } from "vue";

export default defineComponent({
  name: `AddressesContainer`,
  components: {
    Json,
    AddressList,
    PageHeader,
  },
  props: {
    kintin: {
      type: Kintin,
      required: true,
    },
  },
  data: () => ({
    addresses: Array<Address>(),
    isDevMode: window.Kernel.Mode === KernelModes.Dev,
  }),
  mounted(): void {
    this.filter();
  },
  methods: {
    filter() {
      const personIds = [
        ...(this.kintin?.people?.pluck("id") ?? []),
        this.kintin?.primaryPerson?.id,
        this.kintin?.secondaryPerson?.id,
      ].filter(Boolean);

      const people = Person.$findBy({
        id: In(personIds),
      });

      const addresses = people.pluck("profile.addresses");

      const flattenedAddresses = addresses.flat() as Address[];

      this.addresses = flattenedAddresses.unique("summary").sortBy("summary");
    },
  },
});
</script>
