import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { KinvaultSubscription } from "@kinherit/sdk/index";

export const DeleteKinvaultSubscription = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/kinvaults/subscriptions/{kinvaultSubscription}",
  method: "delete",
  parse: (message: string | KinvaultSubscription) => ({
    params: {
      kinvaultSubscription: message,
    },
  }),
  after: ({ message }) => {
    KinvaultSubscription.$delete(message);
  },
});
