// Product

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Product } from "@kinherit/sdk/index";

export const CreateProduct = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/product",
  method: "post",
  parse: (target: Product) => ({
    body: {
      type: target.$data.type,
      text: target.$data.text,
      value: target.$data.value,
      publicText: target.$data.publicText,
      price: target.$data.price,
      allowPackingSlip: target.$data.allowPackingSlip ?? false,
      startDate: target.$data.startDate,
      endDate: target.$data.endDate,
    },
    params: {
      product: target,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
