import { DefineCustomAction } from "@kinherit/framework/action-bus/actions/custom";
import { StripeCoupon } from "@kinherit/sdk/index";

export const SyncKinvaultCoupons = DefineCustomAction({
  interface: "external",
  rootModel: StripeCoupon,
  resource: "/v2/external/cron-job/stripe/coupon",
  method: "put",
  parse: () => ({}),
});
