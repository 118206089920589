import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";

export const ReadKinvaultPriceForm = (): FormBuilder<
  () => {
    title: string;
    stripePriceId: string;
    active: boolean | null;
    created: null | [number, number];
  },
  string
> => {
  return MasterListFiltersForm({
    name: "filter-kinvault-price-form",
    data: () => ({
      title: "",
      stripePriceId: "",
      active: true as null | boolean,
      created: null as null | [number, number],
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      title: [
        FormTextField({
          props: {
            label: "Title",
          },
          models: {
            value: "title",
          },
        }),
      ],
      stripePriceId: [
        FormTextField({
          props: {
            label: "Stripe Price ID",
          },
          models: {
            value: "stripePriceId",
          },
        }),
      ],
      active: [
        FormSelectField({
          props: {
            label: "Active",
            options: {
              true: "Active",
              false: "Inactive",
              null: "Any",
            },
          },
          models: {
            value: {
              get: (data) => {
                if (data.active === null) {
                  return "null";
                } else {
                  return data.active.toString();
                }
              },
              set: (value, data) => {
                if (value === "null") {
                  data.active = null;
                } else {
                  data.active = value === "true";
                }
              },
            },
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
